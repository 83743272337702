import React, { Component } from "react";
import BgBanner from "./BgBanner";
import Grid from "@material-ui/core/Grid";
import FriendsCard from "./FriendsCard";
import UserInfo from "./UserInfo";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import Tabs from "@material-ui/core/Tabs";
import * as APPCONFIG from "../../../config/app-config.js";
import Tab from "@material-ui/core/Tab";
import Delete from "@material-ui/icons/Delete";
import { AiOutlineEdit, AiOutlineSafety } from "react-icons/ai";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { Card, CardContent } from "@material-ui/core";
import { withRouter } from "react-router";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import BlockUi from "react-block-ui";
import MyCommunity from "../HomeUpdated/MyCommunity";
import MarketPlaceCard from "../../../components/MarketPlaceCard.jsx";
import { CgProfile } from "react-icons/cg";
import { GoBriefcase } from "react-icons/go";
import { BsBookmarkStar, BsClipboard } from "react-icons/bs";
import { GrValidate } from "react-icons/gr";
     
import { isYuukkeWorkspace } from "../../../utils.js";
import CalendarContainer from "../../Calender/ProfileCalendar/CalendarContainer.jsx";
let newEducation = new Array(2);

const menus = [
  {
    title: "Profile",
    icon: <CgProfile />,
  },
  {
    title: "Value Match",
    icon: <AiOutlineSafety />,
  },
  {
    title: "Education",
    icon: <BsClipboard />,
  },
  {
    title: "Skills",
    icon: <BsBookmarkStar />,
  },
  {
    title: "Work Experience",
    icon: <GoBriefcase />,
  },
];

class EducationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: 2,
      blocking: false,
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      savebtnState: false,
      inputs: [1],
      saveButtonEnable: false,
      frEducationName: [],
      educationDetails: [],
      editEducation: false,
      dynamicLangArray: [],
      addButton: false,
      editEducationDetail: false,
      editTitle: "",
      nameValue: false,
      endTimeValue: false,
      majorValue: false,
      titleValue: false,
      industrysList: [],
    };
    this.gotoValueMatch = this.gotoValueMatch.bind(this);
    this.gotoBusinessProfile = this.gotoBusinessProfile.bind(this);
    this.handleInputEducation = this.handleInputEducation.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getProfilePhoto = this.getProfilePhoto.bind(this);
    this.getUserCoverPhoto = this.getUserCoverPhoto.bind(this);
    this.getKnowledgePoints = this.getKnowledgePoints.bind(this);
    this.getUpdatedFriendsList = this.getUpdatedFriendsList.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.getCoverPhotos = this.getCoverPhotos.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallHobbies = this.getallHobbies.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.gotoprofession = this.gotoprofession.bind(this);
    this.gotoeducation = this.gotoeducation.bind(this);
    this.gotoprofile = this.gotoprofile.bind(this);
    this.gotoskills = this.gotoskills.bind(this);
    this.addTranslation = this.addTranslation.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.saveEducation = this.saveEducation.bind(this);
    this.saveEducationDetails = this.saveEducationDetails.bind(this);
    this.editEducation = this.editEducation.bind(this);
    this.backEducation = this.backEducation.bind(this);
    this.editEducationDetails = this.editEducationDetails.bind(this);
    this.editEducationList = this.editEducationList.bind(this);
    this.getIndustry = this.getIndustry.bind(this);
    this.getIndustryDetails = this.getIndustryDetails.bind(this);
    this.deleteEducationDetails = this.deleteEducationDetails.bind(this);
  }

  componentDidMount() {
    if (
      this.props.loginDetails.profession_Id == 3 ||
      this.props.loginDetails.profession_Id == 4
    ) {
      this.setState({
        businessProfile: true,
      });
    } else {
      this.setState({
        businessProfile: false,
      });
    }
    this.getEducationDetails();
    //this.getKnowledgePoints();
    this.getIndustry();
    let year = [];
    var currentYear = new Date().getUTCFullYear();
    for (var i = 1970; i <= currentYear; i++) {
      var obj = {};
      obj["id"] = i;
      obj["name"] = i === currentYear ? "Present" : i;
      year.push(obj);
    }
    this.setState({ completionYear: year });
    const data = this.props.loginDetails.profile_uuid;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    console.log("friends****", this.props.FriendsList);
    this.setState({ userPhotoImage: profileImage });
    console.log("login details****", this.props.loginDetails);
    if (this.props.loginDetails.hasOwnProperty("cPhoto")) {
      this.setState({
        coverPhotoImage: banner,
      });
    } else {
      this.getCoverPhotos(this.props.loginDetails.cover_uuid);
    }
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({
      friendsList: AuthService.getFriendsList(),
    });
  }

  addTranslation = (e) => {
    if (
      !this.state.nameValue ||
      !this.state.endTimeValue ||
      !this.state.majorValue ||
      !this.state.titleValue
    ) {
      toast.warn(`${this.props.t("Fill all the field")}`);
    } else {
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
        nameValue: false,
        endTimeValue: false,
        majorValue: false,
        titleValue: false,
      });
    }
  };

  initfunction() {
    this.getallHobbies();
    this.getallGrade();
    this.getFriendsList();
  }
  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
      showAskQuestion: false,
    });
  }

  async removeTranslation(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputs);
    var inpu = this.state.inputs;
    inpu.splice(index, 1);
    if (this.state.frEducationName[index]) {
      var val = this.state.frEducationName;
      val.splice(index, 1);
      this.setState({
        frEducationName: val,
      });
    }
    await this.setState({
      inputs: inpu,
    });
    this.setState({
      nameValue: true,
      endTimeValue: true,
      majorValue: true,
      titleValue: true,
    });

    if (inpu.length == 0) {
      this.setState({
        editEducation: false,
        editEducationDetails: false,
        inputs: [1],
        nameValue: false,
        endTimeValue: false,
        majorValue: false,
        titleValue: false,
      });
    }
  }

  async getCoverPhotos(coverPhoto) {
    console.log("coverPhotoImage : ", coverPhoto);
    if (this.props.loginDetails.cover_uuid != "") {
      console.log("entering if");
      await this.setState({
        coverPhotoImage: `${APPCONFIG.API_URL}auth/image/${coverPhoto}`,
      });
      console.log(this.state.coverPhotoImage);
    } else {
      console.log("entering else");
      await this.setState({ coverPhotoImage: banner });
      console.log(this.state.coverPhotoImage);
    }
  }
  changeMenu = (index) => {
    if (index === 0) {
      this.props.history.push("/my-profile");
    } else if (index === 1) {
      this.props.history.push("/value-match");
    } else if (index === 3) {
      this.props.history.push("/my-skills");
    } else if (index === 4) {
      this.props.history.push("/my-profession");
    }
  };
  render() {
    const { t } = this.props;
    return (
      <Container>
        <ToastContainer position="top-right" autoClose={5000} />
        {/* <Card>
          <CardContent> */}
        <div className="profileMainWrapper">
          {!this.props.firstTime && (
            <h2 className="pageHeading">{t("Profile")}</h2>
          )}
          <div className="profileWrapper">
            {!this.props.firstTime && (
              <BgBanner
                coverPhoto={this.state.coverPhotoImage}
                profilePhoto={this.state.userPhotoImage}
                userName={"User name"}
                getUserPhoto={this.getProfilePhoto}
                getCoverPhoto={this.getUserCoverPhoto}
              />
            )}
            {!this.props.firstTime && (
              <Grid container spacing={2} className="userDetailsWrapper">
                <Grid item md={12}>
                  <UserInfo
                    name={this.props.loginDetails.dName}
                    designation={this.props.loginDetails.biography}
                    additionalInfo={this.props.loginDetails.hsTag}
                    pointsCount={this.state.userKnowledgepoint}
                    loginDetails={this.props.loginDetails}
                  />
                </Grid>
              </Grid>
            )}

            {!this.props.firstTime && (
              <div className="tabWrapper">
                <div className="peopleCardWrapperroups">
                  <Container>
                    <Tabs
                      TabIndicatorProps={{ style: { background: "#A00030" } }}
                      value={this.state.tabIndex}
                      // indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChangeTab}
                      aria-label="disabled tabs example"
                    >
                      <Tab label={t("PROFILE")} />
                      <Tab label={t("FRIENDS")} />
                      <Tab label={t("BLOCKED USER")} />
                      <Tab label={t("FEED")} />
                      <Tab label={t("BOOKMARKED POSTS")} />
                      {isYuukkeWorkspace() && (
     <Tab
       className="tour-user-calendar"
       label={t("MANAGE YOUR SERVICE")}
     />
   )}
                      {this.props.loginDetails?.isVerifySeller && (
                        <Tab label={t("PRODUCTS")} />
                      )}
                    </Tabs>
                  </Container>
                </div>
              
                <div className="profilepageWrapperProfileSetup">
   {this.state.tabIndex === 6 ? (
     <Grid container spacing={0}>
       <Grid item md={12} xs={12} sm={12} lg={12}>
         <CalendarContainer />
       </Grid>
     </Grid>
              
              
              
              
              
                  ) : this.state.tabIndex === 5 ? (
                    <Grid container spacing={0}>
                      <Grid item md={12} xs={12} sm={12} lg={12}>
                        <MarketPlaceCard />
                      </Grid>
                    </Grid>
       )                     : this.state.tabIndex === 0 ? (
                    <div>
                      <Grid container spacing={0}>
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <div className="">
                            <Grid container spacing={1}>
                              <Grid item md={3} xs={12} sm={12} lg={3}>
                                <ul className="profile-card-v1">
                                  {menus.map((item, index) => (
                                    <li
                                      className={
                                        index === this.state.activeMenu
                                          ? "bg-color"
                                          : ""
                                      }
                                      key={index}
                                      onClick={() => this.changeMenu(index)}
                                    >
                                      {item.icon}
                                      <h4>{t(`${item.title}`)}</h4>
                                    </li>
                                  ))}

                                  {/* <div
                                    className="navHomeProfile"
                                    tabindex="1"
                                    onClick={this.gotoprofile}
                                  >
                                    <p
                                      className=""
                                      style={{
                                        color: "#000000",
                                         
                                      }}
                                    >
                                      <img
                                        src={profilePic}
                                        className="profile-img"
                                      />
                                      {t("Profile")}
                                    </p>
                                  </div>
                                  <div
                                    className="navHomeProfile"
                                    tabindex="1"
                                    onClick={this.gotoValueMatch}
                                  >
                                    <p
                                      className=""
                                      style={{
                                        color: "#000000",
                                       }}
                                    >
                                      <img
                                        src={valueMatch}
                                        className="valuematch-img"
                                      />{" "}
                                      {t("Value Match")}
                                    </p>
                                  </div>

                                  <div
                                    className="navHomeProfile"
                                    tabindex="1"
                                    onClick={this.gotoeducation}
                                  >
                                    <p
                                      className=""
                                      style={{
                                        color: "#000000",
                                       }}
                                    >
                                      <img
                                        src={educationFill}
                                        className="education-img"
                                      />{" "}
                                      {t("Education")}
                                    </p>
                                  </div>
                                  <div
                                    className="navHomeProfile"
                                    tabindex="1"
                                    onClick={this.gotoskills}
                                  >
                                    <p
                                      className=""
                                      style={{
                                        color: "#000000",
                                       }}
                                    >
                                      <img
                                        src={skillPic}
                                        className="skill-img"
                                      />
                                      {t("Skills")}
                                    </p>
                                  </div>
                                  <div
                                    className="navHomeProfile"
                                    tabindex="1"
                                    onClick={this.gotoprofession}
                                  >
                                    <p
                                      className=""
                                      style={{
                                        color: "#000000",
                                       }}
                                    >
                                      <img
                                        src={workExperiencePic}
                                        className="profession-img"
                                      />
                                      {t("Work Experience")}
                                    </p>
                                  </div> */}

                                  {/* {this.state.businessProfile && (
                                      <div
                                        className="navHomeProfession"
                                        tabindex="1"
                                        onClick={this.gotoBusinessProfile}
                                      >
                                        <p
                                          className=""
                                          style={{
                                            color: "#000000",
                                            fontSize: "15px",
                                          }}
                                        >
                                          <FcViewDetails
                                            style={{
                                              marginRight: "8px",
                                              width: "22px",
                                              height: "22px",
                                              color: "#000000",
                                            }}
                                          />
                                          {t("Business Profile")}
                                        </p>
                                      </div>
                                    )} */}

                                  {/* <div
                                      className="navHomeProfile"
                                      tabindex="1"
                                      onClick={this.gotoPreference}
                                    >
                                      <p style={{ color: "#000000" }}>
                                        <IoSettingsOutline
                                          style={{
                                            width: "26px",
                                            height: "26px",
                                            marginRight: "17px",
                                            color: "gray",
                                          }}
                                        />

                                        {t("Preferences")}
                                      </p>
                                    </div> */}
                                </ul>
                              </Grid>

                              <Grid item md={9} xs={12} sm={12} lg={9}>
                                {!this.state.editEducation &&
                                  !this.state.editEducationDetail && (
                                    <>
                                      <div className="profileselectCardEducation">
                                        <Grid container spacing={2}>
                                          <Grid item md={6} sm={12} xs={12}>
                                            <h5
                                              style={{
                                                color: "#000000",
                                                // padding: "8px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {t("Education Details")}
                                            </h5>
                                          </Grid>

                                          <Grid item md={6} sm={12} xs={12}>
                                            <div
                                              className=""
                                              style={{ float: "right" }}
                                            >
                                              <button
                                                onClick={this.editEducation}
                                              >
                                                + {t("Add Education")}
                                              </button>
                                            </div>
                                          </Grid>

                                          <Grid
                                            item
                                            md={12}
                                            lg={12}
                                            sm={12}
                                            xs={12}
                                          >
                                            {this.state.educationDetails
                                              .length > 0 &&
                                              this.state.educationDetails.map(
                                                (education, index) => (
                                                  <div className="educationContainerEdit">
                                                    <Grid container spacing={0}>
                                                      <Grid
                                                        item
                                                        md={3}
                                                        xs={6}
                                                        sm={6}
                                                        lg={3}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t(
                                                              "Institution Name"
                                                            )}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {
                                                              education.educationName
                                                            }
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={3}
                                                        xs={6}
                                                        sm={6}
                                                        lg={3}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t(
                                                              "Completion Year"
                                                            )}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {education.endYear}
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={2}
                                                        xs={6}
                                                        sm={6}
                                                        lg={2}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("Title")}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {education.title}
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={2}
                                                        xs={6}
                                                        sm={5}
                                                        lg={2}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("Major")}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {education.major}
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={1}
                                                        xs={2}
                                                        sm={1}
                                                        lg={1}
                                                        //  style={{display:"inline-flex"}}
                                                      >
                                                        <AiOutlineEdit
                                                          style={{
                                                            cursor: "pointer",
                                                            width: "25px",
                                                            height: "25px",
                                                            color: "#A00030",
                                                          }}
                                                          onClick={() =>
                                                            this.editEducationDetails(
                                                              education.id
                                                            )
                                                          }
                                                        ></AiOutlineEdit>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={1}
                                                        xs={2}
                                                        sm={1}
                                                        lg={1}
                                                        // style={{display:"inline-flex"}}
                                                      >
                                                        <Delete
                                                          onClick={() =>
                                                            this.deleteEducationDetails(
                                                              education.id
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                            width: "25px",
                                                            height: "25px",
                                                            color: "#A00030",
                                                          }}
                                                        />{" "}
                                                      </Grid>
                                                    </Grid>
                                                  </div>
                                                )
                                              )}
                                            {this.state.educationDetails
                                              .length == 0 && (
                                              <p>
                                                {t(
                                                  "No education details added yet"
                                                )}
                                              </p>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </>
                                  )}
                                {this.state.editEducation && (
                                  <>
                                    <div className="profileselectCard">
                                      <Grid item md={12} sm={12} xs={12}>
                                        <div
                                          className=""
                                          style={{
                                            float: "left",
                                            margin: "20px",
                                          }}
                                        >
                                          <button onClick={this.backEducation}>
                                            {t("Back")}
                                          </button>
                                        </div>
                                      </Grid>

                                      <BlockUi
                                        tag="div"
                                        blocking={this.state.blocking}
                                        message={t("Loading, please wait")}
                                        color="#A00030"
                                      >
                                        <Grid container spacing={0}>
                                          <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            sm={12}
                                            lg={12}
                                          >
                                            <h6>
                                              {t("Add Education Details")}
                                            </h6>

                                            {this.state.inputs.length > 0 &&
                                              this.state.inputs.map(
                                                (entry, index) => (
                                                  <div>
                                                    <Grid container spacing={0}>
                                                      <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                        sm={12}
                                                        lg={12}
                                                      >
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            fontSize: "35px",
                                                            float: "right",
                                                            marginTop: "12px",
                                                          }}
                                                          class=""
                                                          onClick={() =>
                                                            this.removeTranslation(
                                                              entry,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          -{" "}
                                                        </button>
                                                      </Grid>{" "}
                                                    </Grid>
                                                    <Grid container spacing={1}>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6>
                                                          {t(
                                                            "Institution Name"
                                                          )}
                                                        </h6>
                                                        <TextField
                                                          style={{
                                                            borderRadius:
                                                              "10px",
                                                            marginBottom: "8px",
                                                          }}
                                                          fullWidth
                                                          variant="outlined"
                                                          type="text"
                                                          required=""
                                                          onInput={(e) =>
                                                            (e.target.value =
                                                              e.target.value.slice(
                                                                0,
                                                                70
                                                              ))
                                                          }
                                                          name="frEducationName"
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "name"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].educationName
                                                          }
                                                        />
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6
                                                          style={{
                                                            paddingLeft: "8px",
                                                          }}
                                                        >
                                                          {t("Completion Year")}
                                                        </h6>
                                                        {/* <TextField
                                                          style={{
                                                            borderRadius:
                                                              "10px",
                                                            paddingLeft: "2px",
                                                            marginBottom: "8px",
                                                          }}
                                                          fullWidth
                                                          variant="outlined"
                                                          type="number"
                                                          required=""
                                                          name="frEducationName"
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "endYear"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].endYear
                                                          }
                                                        /> */}

                                                        <TextField
                                                          id="dropdown"
                                                          select
                                                          label=""
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].endYear
                                                          }
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "endYear"
                                                            )
                                                          }
                                                          variant="outlined"
                                                          fullWidth={true}
                                                        >
                                                          {this.state.completionYear.map(
                                                            (option, index) => (
                                                              <MenuItem
                                                                key={option.id}
                                                                value={
                                                                  option.id
                                                                }
                                                              >
                                                                {option.name}
                                                              </MenuItem>
                                                            )
                                                          )}
                                                        </TextField>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1}>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6>{t("Title")}</h6>
                                                        <TextField
                                                          id="dropdown"
                                                          select
                                                          label=""
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].title
                                                          }
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "title"
                                                            )
                                                          }
                                                          variant="outlined"
                                                          fullWidth={true}
                                                          options={
                                                            this.state.skills
                                                          }
                                                        >
                                                          <MenuItem value="">
                                                            <>{t("Select")}</>
                                                          </MenuItem>
                                                          <MenuItem value="UnderGraduate">
                                                            <>
                                                              {t(
                                                                "Under Graduate"
                                                              )}
                                                            </>
                                                          </MenuItem>
                                                          <MenuItem value="PostGraduate">
                                                            <>
                                                              {t(
                                                                "Post Graduate"
                                                              )}
                                                            </>
                                                          </MenuItem>
                                                          <MenuItem value="Doctorate">
                                                            <>
                                                              {t("Doctorate")}
                                                            </>
                                                          </MenuItem>
                                                          <MenuItem value="Diploma">
                                                            <>{t("Diploma")}</>
                                                          </MenuItem>
                                                          <MenuItem value="Other">
                                                            <>{t("Other")}</>
                                                          </MenuItem>
                                                        </TextField>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6>{t("Major")}</h6>
                                                        <TextField
                                                          style={{
                                                            borderRadius:
                                                              "10px",
                                                            marginBottom: "8px",
                                                          }}
                                                          fullWidth
                                                          variant="outlined"
                                                          type="text"
                                                          required=""
                                                          name="frEducationName"
                                                          onInput={(e) =>
                                                            (e.target.value =
                                                              e.target.value.slice(
                                                                0,
                                                                40
                                                              ))
                                                          }
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "major"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].major
                                                          }
                                                        />
                                                      </Grid>
                                                    </Grid>

                                                    <Grid
                                                      container
                                                      spacing={0}
                                                      style={{
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                        sm={12}
                                                        lg={12}
                                                      ></Grid>
                                                    </Grid>
                                                  </div>
                                                )
                                              )}
                                            <Grid container spacing={0}>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                {
                                                  <div className="profileFormBtn">
                                                    <button
                                                      style={{
                                                        borderRadius: "10px",
                                                        padding: "8px",
                                                      }}
                                                      onClick={
                                                        this.saveEducation
                                                      }
                                                    >
                                                      {t("Save")}
                                                    </button>
                                                  </div>
                                                }
                                              </Grid>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <button
                                                  style={{
                                                    cursor: "pointer",
                                                    marginTop: "20px",
                                                    float: "right",
                                                  }}
                                                  class=""
                                                  onClick={this.addTranslation}
                                                >
                                                  +
                                                </button>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </BlockUi>
                                    </div>
                                  </>
                                )}
                                {this.state.editEducationDetail && (
                                  <>
                                    <div className="profileselectCard">
                                      <Grid item md={12} sm={12} xs={12}>
                                        <div
                                          className=""
                                          style={{
                                            float: "left",
                                            margin: "20px",
                                          }}
                                        >
                                          <button onClick={this.backEducation}>
                                            {t("Back")}
                                          </button>
                                        </div>
                                      </Grid>

                                      <Grid container spacing={0}>
                                        <Grid
                                          item
                                          md={12}
                                          xs={12}
                                          sm={12}
                                          lg={12}
                                        >
                                          <h6 style={{ fontWeight: "bold" }}>
                                            {t("Edit Education Details")}
                                          </h6>

                                          <div>
                                            <Grid container spacing={1}>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6>{t("Institution Name")}</h6>
                                                <TextField
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editInsitutionName"
                                                  onInput={(e) =>
                                                    (e.target.value =
                                                      e.target.value.slice(
                                                        0,
                                                        70
                                                      ))
                                                  }
                                                  value={
                                                    this.state
                                                      .editInsitutionName
                                                  }
                                                  onChange={this.handleForm}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6
                                                  style={{
                                                    paddingLeft: "8px",
                                                  }}
                                                >
                                                  {t("Completion Year")}
                                                </h6>
                                                {/* <TextField
                                                    style={{
                                                      borderRadius: "10px",
                                                      paddingLeft: "2px",
                                                      marginBottom: "8px",
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    type="number"
                                                    required=""
                                                    name="editcompleteYear"
                                                    value={
                                                      this.state
                                                        .editcompleteYear
                                                    }
                                                    onChange={this.handleForm}
                                                  /> */}
                                                <TextField
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  id="dropdown"
                                                  select
                                                  label=""
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editcompleteYear"
                                                  value={
                                                    this.state.editcompleteYear
                                                  }
                                                  onChange={
                                                    this.handleFormDropDown
                                                  }
                                                >
                                                  {this.state.completionYear.map(
                                                    (option, index) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}{" "}
                                                </TextField>
                                              </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6>{t("Title")}</h6>
                                                <TextField
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  id="dropdown"
                                                  select
                                                  label=""
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editTitle"
                                                  value={this.state.editTitle}
                                                  onChange={this.handleForm}
                                                >
                                                  <MenuItem value="">
                                                    <>{t("Select")}</>
                                                  </MenuItem>
                                                  <MenuItem value="UnderGraduate">
                                                    <>{t("Under Graduate")}</>
                                                  </MenuItem>
                                                  <MenuItem value="PostGraduate">
                                                    <>{t("Post Graduate")}</>
                                                  </MenuItem>
                                                  <MenuItem value="Doctorate">
                                                    <>{t("Doctorate")}</>
                                                  </MenuItem>
                                                  <MenuItem value="Diploma">
                                                    <>{t("Diploma")}</>
                                                  </MenuItem>
                                                  <MenuItem value="Other">
                                                    <>{t("Other")}</>
                                                  </MenuItem>
                                                </TextField>
                                              </Grid>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6>{t("Major")}</h6>
                                                <TextField
                                                  onInput={(e) =>
                                                    (e.target.value =
                                                      e.target.value.slice(
                                                        0,
                                                        40
                                                      ))
                                                  }
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editMajor"
                                                  value={this.state.editMajor}
                                                  onChange={this.handleForm}
                                                />
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              container
                                              spacing={0}
                                              style={{ marginTop: "5px" }}
                                            ></Grid>
                                          </div>

                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              md={6}
                                              xs={6}
                                              sm={6}
                                              lg={6}
                                            >
                                              {
                                                <div className="profileFormBtn">
                                                  <button
                                                    style={{
                                                      borderRadius: "10px",
                                                      padding: "8px",
                                                    }}
                                                    onClick={
                                                      this.editEducationList
                                                    }
                                                  >
                                                    {t("Update")}
                                                  </button>
                                                </div>
                                              }
                                            </Grid>
                                            <Grid
                                              item
                                              md={6}
                                              xs={6}
                                              sm={6}
                                              lg={6}
                                            ></Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 1 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Friends")}
                          </p>
                        </Grid>
                        {this.props.FriendsList &&
                          this.props.FriendsList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                friendId={data.userId}
                                fUserId={data.userProfile.userId}
                                image={data.profilePhoto}
                                name={`${data.userProfile.firstName} ${data.userProfile.lastName}`}
                                Dname={data.userProfile.displayName}
                                smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.FriendsList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No friends available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 2 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Blocked User")}
                          </p>{" "}
                        </Grid>
                        {this.props.blockedUserList &&
                          this.props.blockedUserList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                tabIndex={"BlockedUser"}
                                friendId={data.blockUserId}
                                fUserId={data.blockUserId}
                                image={data.profilePhoto}
                                name={data.userName}
                                Dname={data.userName}
                                // smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.blockedUserList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No More Blocked List Available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : (
                    (this.state.tabIndex === 3 ||
                      this.state.tabIndex === 4) && (
                      <div style={{ marginTop: "5px" }}>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <MyCommunity
                              type={
                                this.state.tabIndex === 3 ? "Feed" : "BookMark"
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* </CardContent>
        </Card> */}
      </Container>
    );
  }
  getIndustry() {
    var industryList = this.props.industryList;
    console.log("loginDetails", this.props.loginDetails);
    console.log("industryList", industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      this.setState({ industrysList: lList });
      console.log("industrysList:", lList);
    }
    this.getIndustryDetails();
  }

  gotoValueMatch() {
    this.props.history.push("/value-match");
  }
  getIndustryDetails() {
    var saveEducation = {};
    ProfileService.getWorkExperienceDetails(saveEducation).then((response) => {
      var result = response.data;

      if (response.data.status === 1) {
        this.setState({
          //  educationDetails: response.data.data.workExperience,
        });
        var educationData = response.data.data.workExperience;

        // for (var j = 0; j < this.state.industrysList.length; j++) {
        //   for (var i = 0; i < educationData.length; i++) {
        //     if (
        //       this.state.industrysList[j].id === educationData[i].industry
        //     ) {
        //       if (educationData[i].industry === 18) {
        //         this.setState({
        //        //   businessProfile: true,
        //         });

        //       } else {
        //         this.setState({
        //        //   businessProfile: false,
        //         });
        //       }
        //     }
        //   }}
      }
    });
  }
  handleInputEducation(event, index, type) {
    if (type == "name") {
      if (event.target.value == "") {
        this.setState({
          nameValue: false,
        });
      } else {
        this.setState({
          nameValue: true,
        });
      }
    } else if (type == "endYear") {
      if (event.target.value == "") {
        this.setState({
          endTimeValue: false,
        });
      } else {
        this.setState({
          endTimeValue: true,
        });
      }
    } else if (type == "major") {
      if (event.target.value == "") {
        this.setState({
          majorValue: false,
        });
      } else {
        this.setState({
          majorValue: true,
        });
      }
    } else if (type == "title") {
      if (event.target.value == "") {
        this.setState({
          titleValue: false,
        });
      } else {
        this.setState({
          titleValue: true,
        });
      }
    }

    console.log("type inputs values", type);
    console.log("type inputs event", event.target.value);

    console.log(event);

    var newEducations = this.state.frEducationName;
    if (newEducations[index]) {
      if (type == "name") {
        event.preventDefault();
        event.stopPropagation();

        var obj = newEducations[index];
        obj.educationName = event.target.value;
        newEducations[index] = obj;
      } else if (type == "endYear") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.endYear = event.target.value;
        newEducations[index] = obj;
      } else if (type == "major") {
        // if (
        //   /^[A-Za-z]+$/.test(event.target.value) ||
        //   event.target.value == ""
        // ) {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.major = event.target.value;
        newEducations[index] = obj;
      } else if (type == "title") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.title = event.target.value;
        newEducations[index] = obj;
      } else {
        event.preventDefault();
        event.stopPropagation();
        var obj = newEducations[index];
        obj.endYear = event.target.value;
        newEducations[index] = obj;
      }
    } else {
      if (type == "name") {
        event.preventDefault();
        event.stopPropagation();

        // if (/^[A-Za-z]+$/.test(event.target.value)) {
        var obj = newEducations[index];
        var obj = {
          educationName: event.target.value,
          endYear: "",
          title: "",
          major: "",
        };
        newEducations[index] = obj;
        // } else {
        //   var obj = newEducations[index];
        //   var obj = {
        //     educationName: "",
        //     endYear: "",
        //     title: "",
        //     major: "",
        //   };
        //   newEducations[index] = obj;
        // }
      } else if (type == "endYear") {
        var data = event.target.value;
        var obj = newEducations[index];
        var obj = {
          educationName: "",
          endYear: event.target.value,
          title: "",
          major: "",
        };
        console.log("inputsss");
        console.log(obj);
        newEducations[index] = obj;
      } else if (type == "major") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        // obj.major = event.target.value;
        var obj = {
          educationName: "",
          endYear: "",
          title: "",
          major: event.target.value,
        };
        newEducations[index] = obj;
      } else if (type == "title") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        // obj.title = event.target.value;
        var obj = {
          educationName: "",
          endYear: "",
          title: event.target.value,
          major: "",
        };
        newEducations[index] = obj;
      } else {
        event.preventDefault();
        event.stopPropagation();
        // var obj = {
        //   educationName: "",
        //   // fromYear: "",
        //   endYear: event.target.value,
        // };
        // newEducations[index] = obj;
      }
    }

    this.setState({
      frEducationName: newEducations,
    });
    console.log("this.state.frEducationName");
    console.log(newEducation);
    console.log(this.state.frEducationName);
    //  this.setState({answers:answer_id.target.value})
  }
  handleFormDropDown = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleForm(event) {
    if (/^[A-Za-z\s]+$/.test(event.target.value) || event.target.value == "") {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleClickEdit() {
    this.setState({ savebtnState: false });
  }

  gotoprofession() {
    this.props.history.push("/my-profession");
  }
  gotoPreference = () => {
    this.props.history.push("/my-preference");
  };
  gotoBusinessProfile() {
    this.props.history.push("my-business-profile");
  }
  gotoeducation() {
    this.props.history.push("/my-education");
  }

  gotoprofile() {
    this.props.history.push("/my-profile");
  }

  gotoskills() {
    this.props.history.push("/my-skills");
  }

  saveEducation() {
    if (
      this.state.nameValue &&
      this.state.endTimeValue &&
      this.state.majorValue &&
      this.state.titleValue
    ) {
      this.saveEducationDetails();
    } else {
      toast.warn(`${this.props.t("Please fill the all Filed..")}`);
    }

    // this.saveEducationDetails();
  }

  //

  saveEducationDetails() {
    this.setState({ blocking: true });
    var saveEducation = {
      education: this.state.frEducationName,
      firstTimeSetup: "true",
    };
    ProfileService.saveEducationDetails(saveEducation)
      .then((response) => {
        var result = response.data;

        var data = [];
        console.log(this.state.frEducationName);
        for (let i = 0; i < this.state.frEducationName.length; i++) {
          // data.push({
          //   educationName: "",
          //   fromYear: "",
          //   endYear: "",
          // });
          this.setState({
            frEducationName: data,
            editEducation: false,
            editEducationDetail: false,
            inputs: [0],
          });
          this.getEducationDetails();
        }
        toast.success(`${this.props.t("Saved successfully")}`);
        this.setState({ blocking: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ blocking: false });
      });
  }
  editEducationList() {
    if (
      this.state.editInsitutionName == "" ||
      this.state.editMajor.trim() == "" ||
      this.state.editcompleteYear == "" ||
      this.state.editTitle.trim() == ""
    ) {
      toast.warn(`${this.props.t("Please fill the all Filed..")}`);
    } else {
      this.setState({ blocking: true });
      var saveEducation = {
        editId: this.state.editId,
        institutionName: this.state.editInsitutionName.trim(),
        major: this.state.editMajor.trim(),
        completeYear: this.state.editcompleteYear,
        title: this.state.editTitle,
      };
      ProfileService.editEducationList(saveEducation)
        .then((response) => {
          var result = response.data;
          var data = [];
          this.setState({ blocking: false });
          this.getEducationDetails();
          toast.success(`${this.props.t("Updated Successfully")}`);
          this.setState({
            editEducationDetail: false,
          });
        })
        .catch((error) => {
          this.setState({ blocking: false });
          console.log(error);
        });
    }
  }

  getEducationDetails() {
    var saveEducation = {};
    ProfileService.getEducationDetails(saveEducation)
      .then((response) => {
        var result = response.data;

        console.log("education details");
        console.log(response.data.data);
        if (response.data.status == 1) {
          this.setState({ educationDetails: response.data.data.education });
          var educationData = response.data.data.education;

          console.log("education" + educationData);
          console.log(educationData);
          this.setState({
            educationData,
          });
          this.setState({ addButton: true });
          // Object.keys(educationData).map((k) => {
          //   console.log(k);
          //   console.log(k.toString());

          //   // this.setState({
          //   //   dynamicLangArray: [
          //   //     ...this.state.dynamicLangArray,
          //   //     k
          //   //   ]
          //   // });
          // });

          // let unique_dynamic_key = [... new Set(this.state.dynamicLangArray)];

          // unique_dynamic_key.map(k => {
          //   var educationName = "";
          //   var fromYear = "";
          //   var endYear = "";
          //   var title = "";
          //   var major = "";
          //   if (k in this.state.educationData) {
          //     console.log(k + " in announment");
          //     educationName = this.state.educationData[k].educationName;
          //     fromYear = this.state.educationData[k].fromYear;
          //     endYear = this.state.educationData[k].endYear;
          //     title = this.state.educationData[k].title;
          //     major = this.state.educationData[k].major;
          //   }

          //   var obj = {
          //     "educationName": educationName,
          //     "fromYear": fromYear,
          //     "endYear": endYear,
          //     "title": title,
          //     "major": major,
          //   };

          //   this.setState({
          //     frEducationName: [
          //       ...this.state.frEducationName,
          //       obj
          //     ]
          //   });
          // });

          if (this.state.frEducationName.length > 0) {
            this.setState({ inputs: [] });
            for (let k = 0; k < this.state.frEducationName.length; k++) {
              var input = 1;

              this.setState({
                inputs: [...this.state.inputs, input],
              });
            }
          }
        } else {
          this.setState({ educationDetails: [] });
          this.setState({ addButton: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUpdatedFriendsList() {
    let getFriendsList = {};
    FriendsFinder.getFriendsList(getFriendsList)
      .then((response) => {
        var result = response.data;
        this.setState({
          friendsList: result.data.friendsList,
        });
        AuthService.storeFriendsList(result.data.friendsList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProfilePhoto(userPhoto) {
    this.setState({ userPhotoImage: `data:image/jpeg;base64,${userPhoto}` });
  }
  getUserCoverPhoto(coverPhoto) {
    this.setState({
      coverPhotoImage: `data:image/jpeg;base64,${coverPhoto}`,
      coverPhotoImageState: true,
    });
  }

  async getKnowledgePoints() {
    let getKnowledgePoints = { userId: this.props.loginDetails.uId };
    var response = ProfileService.getKPCache(getKnowledgePoints);
    if (response) {
      var result = response.data;
      if (result.data === null) {
        this.setState({ userKnowledgepoint: 0 });
      } else {
        this.setState({ userKnowledgepoint: result.data });
      }
    } else {
      ProfileService.getKnowledgePointByUser(getKnowledgePoints)
        .then((response) => {
          var result = response.data;
          if (result.data === null) {
            this.setState({ userKnowledgepoint: 0 });
          } else {
            ProfileService.setKPCache(getKnowledgePoints, response);
            this.setState({ userKnowledgepoint: result.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getallHobbies() {
    ProfileService.getallHobbies()
      .then((response) => {
        console.log(response);
        var result = response.data;
        AuthService.storeHobbiesList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        AuthService.storeGradeList(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFriendsList() {
    var friend = AuthService.getFriendsList();
    this.setState({
      FriendsList: friend,
    });
  }

  editEducation() {
    this.setState({ editEducation: true });
  }

  backEducation() {
    this.setState({
      editEducation: false,
      editEducationDetail: false,
      frEducationName: [],
      inputs: [1],
    });
  }
  editEducationDetails(id) {
    this.setState({ editEducationDetail: true });
    for (var i = 0; i < this.state.educationDetails.length; i++) {
      if (this.state.educationDetails[i].id == id) {
        console.log("Education", this.state.educationDetails[i].id);
        this.setState({
          editId: this.state.educationDetails[i].id,
          editInsitutionName: this.state.educationDetails[i].educationName,
          editcompleteYear: this.state.educationDetails[i].endYear,
          editTitle: this.state.educationDetails[i].title,
          editMajor: this.state.educationDetails[i].major,
        });
      }
    }
  }
  deleteEducationDetails(id) {
    var data = {
      deleteId: id,
    };
    ProfileService.deleteEducationDetails(data)
      .then((response) => {
        toast.success(`${this.props.t("Deleted successfully")}`);

        console.log(response);
        this.getEducationDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  console.log("Loading Notification Props");
  console.log(state.loginDetailsApi.loginDetails);

  return {
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    industryList: state.commonApi.industrylist.data,
    blockedUserList: state.commonApi.blockedUserList,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(EducationContainer)));
