import MyYuukke from "../../api/MyYuukke";
import {
  CALENDAR_SERVICE_LIST,
  CATEGORY_V2_LIST,
  EVENT_LIST,
  EVENT_LOADER,
  EVENT_VIDEO_LIST,
  GET_SUBSCRIPTION_LIST,
  GET_USER_SERVICE,
  GET_USER_SLOTS,
  MODIFY_SUBSCRIPTION_STATUS,
  SPECIALIZATION_V2_LIST,
  USER_SUBSCRIPTION_STATUS,
} from "../types";

export const getEventDetails = (data) => async (dispatch) => {
  dispatch({
    type: EVENT_LOADER,
    payload: true,
  });
  MyYuukke.getEventList(data)
    .then((response) => {
      dispatch({
        type: EVENT_LIST,
        payload: response.data.data,
        page: data.page,
      });
    })
    .catch((e) => {
      console.log("event-list-error", e);
    });
};
export const getEventVideos = (data) => async (dispatch) => {
  dispatch({
    type: EVENT_LOADER,
    payload: true,
  });
  MyYuukke.getEventVideoList(data)
    .then((response) => {
      dispatch({
        type: EVENT_VIDEO_LIST,
        payload: response.data.data,
        page: data.page,
      });
    })
    .catch((e) => {
      console.log("event-list-error", e);
    });
};

export const getUserSlots = () => async (dispatch) => {
  dispatch({
    type: EVENT_LOADER,
    payload: true,
  });
  MyYuukke.getUserSlots()
    .then((response) => {
      dispatch({
        type: GET_USER_SLOTS,
        payload: response.data.data,
      });
    })
    .catch((e) => {
      console.log("event-list-error", e);
    });
};

export const saveCalenderSlot =
  (eventName, hours, duration, type, eventId) => async (dispatch) => {
    // dispatch({
    //   type: EVENT_LOADER,
    //   payload: true,
    // });
    let data = {
      eventName: eventName,
      weeklyHours: hours,
      meetingActiveDuration: duration,
      type: type,
    };
    if (eventId) {
      data.id = eventId;
    }
    MyYuukke.saveUserSlots(data)
      .then((response) => {
        console.log("response", response);

        // dispatch({
        //   type: SAVE_USER_SLOT,
        //   payload: response.data.data,
        // });
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
export const saveCalenderDateSpecificHours =
  (date, time, calenderId) => async (dispatch) => {
    dispatch({
      type: EVENT_LOADER,
      payload: true,
    });
    let data = {
      dateSlot: date,
      timeSlot: time,
      calendarEventId: calenderId,
    };

    MyYuukke.saveUserDateSlot(data)
      .then((response) => {
        console.log("response", response);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

export const saveUserSlot = (data) => async (dispatch) => {
  dispatch({
    type: EVENT_LOADER,
    payload: true,
  });

  dispatch({
    type: GET_USER_SLOTS,
    payload: data,
  });
};

export const getSubscriptionList =
  (type = "service") =>
  async (dispatch) => {
    MyYuukke.subscriptionList(type)
      .then((response) => {
        dispatch({
          type: GET_SUBSCRIPTION_LIST,
          payload: response.data.data,
        });
      })
      .catch((e) => {
        console.log("event-list-error", e);
      });
  };

export const getSubscriptionStatus = (type) => async (dispatch) => {
  let data = {
    restrictiontype: type,
  };
  MyYuukke.checkPaymentStatus(data)
    .then((response) => {
      dispatch({
        type: USER_SUBSCRIPTION_STATUS,
        payload: response.data.data,
        subscriptionType: type,
      });
    })
    .catch((e) => {
      console.log("event-list-error", e);
    });
};

export const subscriptionModelStatus = (value) => async (dispatch) => {
  dispatch({
    type: MODIFY_SUBSCRIPTION_STATUS,
    payload: value,
  });
};

export const getCategoryListV2 = () => async (dispatch) => {
  MyYuukke.getCategoryListV2()
    .then((response) => {
      let list = response.data.data.map((item) => {
        return {
          value: item.subCategoryId,
          label: item.subCategoryName,
          pId: item.categoryId,
        };
      });
      dispatch({
        type: CATEGORY_V2_LIST,
        payload: list,
      });
    })
    .catch((e) => {
      console.log("event-list-error", e);
    });
};

export const getCalendarServices = () => async (dispatch) => {
  MyYuukke.getCalendarServices()
    .then((response) => {
      dispatch({
        type: CALENDAR_SERVICE_LIST,
        payload: response.data.data,
      });
    })
    .catch((e) => {
      console.log("event-list-error", e);
    });
};

export const getDirectoryCategoryV1 = () => async (dispatch) => {
  MyYuukke.getDirectoryCategoryV1()
    .then((response) => {
      console.log("getDirectoryCategoryV1", response.data.data);
      let category = response.data.data.map((item) => {
        return { value: item.categoryId, label: item.categoryName };
      });
      let obj = {
        category: category,
        subCategory: response.data.data,
      };
      // let list = response.data.data.map((item) => {
      //   return { value: item.id, label: item.labelName };
      // });
      dispatch({
        type: SPECIALIZATION_V2_LIST,
        payload: obj,
      });
    })
    .catch((e) => {
      console.log("event-list-error", e);
    });
};

export const getUserService = (type, value) => async (dispatch) => {
  if (type === "update") {
    dispatch({
      type: GET_USER_SERVICE,
      payload: value,
    });
  } else {
    MyYuukke.getUserService()
      .then((response) => {
        dispatch({
          type: GET_USER_SERVICE,
          payload: response.data.data,
        });
      })
      .catch((e) => {
        console.log("event-list-error", e);
      });
  }
};
