import React, { Component } from "react";
import BgBanner from "./BgBanner";
import Grid from "@material-ui/core/Grid";
import FriendsCard from "./FriendsCard";
import UserInfo from "./UserInfo";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { AiOutlineEdit, AiOutlineSafety } from "react-icons/ai";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { Card, CardContent } from "@material-ui/core";
import { withRouter } from "react-router";
import * as APPCONFIG from "../../../config/app-config.js";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Delete from "@material-ui/icons/Delete";
import BlockUi from "react-block-ui";
import MyCommunity from "../HomeUpdated/MyCommunity";
import MarketPlaceCard from "../../../components/MarketPlaceCard.jsx";

import { CgProfile } from "react-icons/cg";
import { GoBriefcase } from "react-icons/go";
import { BsBookmarkStar, BsClipboard } from "react-icons/bs";
import { GrValidate } from "react-icons/gr";
import { isYuukkeWorkspace } from "../../../utils.js";
import CalendarContainer from "../../Calender/ProfileCalendar/CalendarContainer.jsx";
const menus = [
  {
    title: "Profile",
    icon: <CgProfile />,
  },
  {
    title: "Value Match",
    icon: <AiOutlineSafety />,
  },
  {
    title: "Education",
    icon: <BsClipboard />,
  },
  {
    title: "Skills",
    icon: <BsBookmarkStar />,
  },
  {
    title: "Work Experience",
    icon: <GoBriefcase />,
  },
];
let newEducation = new Array(2);
class ProfessionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      savebtnState: false,
      inputs: [1],
      saveButtonEnable: false,
      frEducationName: [],
      educationDetails: [],
      editEducation: false,
      dynamicLangArray: [],
      industrysList: [],
      addButton: false,
      editEducationDetail: false,
      editTitle: "",
      companyNameValue: false,
      toYearValue: false,
      roleValue: false,
      fromYearValue: false,
      industryValue: false,
      jobFunctionValue: false,
      businessProfile: false,
      activeMenu: 4,
    };
    this.gotoValueMatch = this.gotoValueMatch.bind(this);
    this.handleInputEducation = this.handleInputEducation.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getProfilePhoto = this.getProfilePhoto.bind(this);
    this.getUserCoverPhoto = this.getUserCoverPhoto.bind(this);
    this.getKnowledgePoints = this.getKnowledgePoints.bind(this);
    this.getUpdatedFriendsList = this.getUpdatedFriendsList.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.getCoverPhotos = this.getCoverPhotos.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallHobbies = this.getallHobbies.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.gotoprofession = this.gotoprofession.bind(this);
    this.gotoBusinessProfile = this.gotoBusinessProfile.bind(this);
    this.gotoeducation = this.gotoeducation.bind(this);
    this.gotoprofile = this.gotoprofile.bind(this);
    this.gotoskills = this.gotoskills.bind(this);
    this.addTranslation = this.addTranslation.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.saveEducation = this.saveEducation.bind(this);
    this.saveEducationDetails = this.saveEducationDetails.bind(this);
    this.editEducation = this.editEducation.bind(this);
    this.backEducation = this.backEducation.bind(this);
    this.editEducationDetails = this.editEducationDetails.bind(this);
    this.editEducationList = this.editEducationList.bind(this);
    this.getIndustry = this.getIndustry.bind(this);
    this.getSchool = this.getSchool.bind(this);
    this.deleteProfessionDetails = this.deleteProfessionDetails.bind(this);
  }

  componentDidMount() {
    this.getIndustry();
    if (
      this.props.loginDetails.profession_Id == 3 ||
      this.props.loginDetails.profession_Id == 4
    ) {
      this.setState({
        businessProfile: true,
      });
    } else {
      this.setState({
        businessProfile: false,
      });
    }
    this.getEducationDetails();
    //  this.getKnowledgePoints();
    this.getSchool();
    let year = [];
    var currentYear = new Date().getUTCFullYear();
    for (var i = 1970; i <= currentYear; i++) {
      var obj = {};
      obj["id"] = i;
      obj["name"] = i;
      year.push(obj);
    }
    this.setState({ startYear: year });
    const data = this.props.loginDetails.profile_uuid;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    console.log("friends****", this.props.FriendsList);
    this.setState({ userPhotoImage: profileImage });
    if (this.props.loginDetails.hasOwnProperty("cPhoto")) {
      this.setState({
        coverPhotoImage: banner,
      });
    } else {
      this.getCoverPhotos(this.props.loginDetails.cover_uuid);
    }
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({
      friendsList: AuthService.getFriendsList(),
    });
  }
  changeMenu = (index) => {
    if (index === 0) {
      this.props.history.push("/my-profile");
    } else if (index === 1) {
      this.props.history.push("/value-match");
    } else if (index === 2) {
      this.props.history.push("/my-education");
    } else if (index === 3) {
      this.props.history.push("/my-skills");
    }
  };
  getSchool() {
    var schoolList = this.props.schoolList;
    console.log("schoolList" + schoolList);
    if (schoolList) {
      var lList = [];
      for (var i = 0; i < schoolList.length; i++) {
        var obj = {};
        obj["id"] = schoolList[i].schoolId;
        obj["name"] = schoolList[i].schoolName;
        lList.push(obj);
      }

      this.setState({ schools: lList });
      console.log(lList);
    }
    console.log(this.state.schools);
  }
  addTranslation = (e) => {
    if (
      !this.state.companyNameValue ||
      !this.state.toYearValue ||
      !this.state.roleValue ||
      !this.state.fromYearValue ||
      !this.state.industryValue ||
      !this.state.jobFunctionValue
    ) {
      toast.warn(`${this.props.t("Fill all the field")}`);
    } else {
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
        companyNameValue: false,
        toYearValue: false,
        fromYearValue: false,
        roleValue: false,
        industryValue: false,
        jobFunctionValue: false,
      });
    }
  };

  initfunction() {
    this.getallHobbies();
    this.getallGrade();
    this.getFriendsList();
  }
  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
      showAskQuestion: false,
    });
  }
  getIndustry() {
    var industryList = this.props.industryList;
    console.log("loginDetails", this.props.loginDetails);
    console.log("industryList", industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      this.setState({ industrysList: lList });
      console.log("industrysList:", lList);
    }
  }
  async removeTranslation(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    this.getIndustry();
    console.log(this.state.inputs);
    var inpu = this.state.inputs;
    inpu.splice(index, 1);
    if (this.state.frEducationName[index]) {
      var val = this.state.frEducationName;
      val.splice(index, 1);
      this.setState({
        frEducationName: val,
      });
    }
    await this.setState({
      inputs: inpu,
    });
    this.setState({
      companyNameValue: true,
      toYearValue: true,
      fromYearValue: true,
      roleValue: true,
      industryValue: true,
      jobFunctionValue: true,
    });

    if (inpu.length == 0) {
      this.setState({
        editEducation: false,
        editEducationDetails: false,
        inputs: [1],
      });
    }
  }

  async getCoverPhotos(coverPhoto) {
    console.log("coverPhotoImage");

    if (this.props.loginDetails.cover_uuid != "") {
      console.log("entering if");
      await this.setState({
        coverPhotoImage: `${APPCONFIG.API_URL}auth/image/${coverPhoto}`,
      });
      console.log(this.state.coverPhotoImage);
    } else {
      console.log("entering else");
      await this.setState({ coverPhotoImage: banner });
      console.log(this.state.coverPhotoImage);
    }
  }
  render() {
    const { t } = this.props;
    return (
      <Container>
        <ToastContainer position="top-right" autoClose={5000} />
        {/* <Card>
          <CardContent> */}
        <div className="profileMainWrapper">
          {!this.props.firstTime && (
            <h2 className="pageHeading">{t("Profile")}</h2>
          )}
          <div className="profileWrapper">
            {!this.props.firstTime && (
              <BgBanner
                coverPhoto={this.state.coverPhotoImage}
                profilePhoto={this.state.userPhotoImage}
                userName={"User name"}
                getUserPhoto={this.getProfilePhoto}
                getCoverPhoto={this.getUserCoverPhoto}
              />
            )}
            {!this.props.firstTime && (
              <Grid container spacing={2} className="userDetailsWrapper">
                <Grid item md={12}>
                  <UserInfo
                    name={this.props.loginDetails.dName}
                    designation={this.props.loginDetails.biography}
                    additionalInfo={this.props.loginDetails.hsTag}
                    pointsCount={this.state.userKnowledgepoint}
                    loginDetails={this.props.loginDetails}
                  />
                </Grid>
              </Grid>
            )}

            {!this.props.firstTime && (
              <div className="tabWrapper">
                {/* <div className="tabsProfile"> */}
                <div>
                  <Container>
                    <div className="flexDefault  ">
                      <Tabs
                        TabIndicatorProps={{
                          style: { background: "#A00030" },
                        }}
                        value={this.state.tabIndex}
                        // indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeTab}
                        aria-label="disabled tabs example"
                      >
                        <Tab label={t("PROFILE")} />
                        <Tab label={t("FRIENDS")} />
                        <Tab label={t("BLOCKED USER")} />
                        <Tab label={t("FEED")} />
                        <Tab label={t("BOOKMARKED POSTS")} />
                        {isYuukkeWorkspace() && (
      <Tab
        className="tour-user-calendar"
        label={t("MANAGE YOUR SERVICE")}
      />
    )}
                        {this.props.loginDetails?.isVerifySeller && (
                          <Tab label={t("PRODUCTS")} />
                        )}
                      </Tabs>
                    </div>
                  </Container>
                </div>
                 
                <div className="profilepageWrapperProfileSetup">
    {this.state.tabIndex === 5 ? (
      <Grid container spacing={0}>
        <Grid item md={12} xs={12} sm={12} lg={12}>
          <CalendarContainer />
        </Grid>
      </Grid>
                 
                 
                 
                 
                  ) : this.state.tabIndex === 6 ? ( // Add the new condition for tabIndex === 6
                    <Grid container spacing={0}>
                      <Grid item md={12} xs={12} sm={12} lg={12}>
                      <MarketPlaceCard />
                        
                      </Grid>
                    </Grid>
               )             : this.state.tabIndex === 0 ? (
                    <div>
                      <Grid container spacing={0}>
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <div className="">
                            <Grid container spacing={1}>
                              <Grid item md={3} xs={12} sm={12} lg={3}>
                                <ul className="profile-card-v1">
                                  {menus.map((item, index) => (
                                    <li
                                      className={
                                        index === this.state.activeMenu
                                          ? "bg-color"
                                          : ""
                                      }
                                      key={index}
                                      onClick={() => this.changeMenu(index)}
                                    >
                                      {item.icon}
                                      <h4>{t(`${item.title}`)}</h4>
                                    </li>
                                  ))}
                                </ul>
                              </Grid>

                              <Grid item md={9} xs={12} sm={12} lg={9}>
                                {!this.state.editEducation &&
                                  !this.state.editEducationDetail && (
                                    <>
                                      <div className="profileselectCardExpMobile">
                                        <Grid container spacing={2}>
                                          <Grid item md={6} sm={12} xs={12}>
                                            <h5
                                              style={{
                                                color: "#000000",
                                                // padding: "8px",
                                              }}
                                            >
                                              {t("Work Experience Details")}
                                            </h5>
                                          </Grid>

                                          <Grid item md={6} sm={12} xs={12}>
                                            <div
                                              className=""
                                              style={{ float: "right" }}
                                            >
                                              <button
                                                onClick={this.editEducation}
                                              >
                                                + {t("Add Work Experience")}
                                              </button>
                                            </div>
                                          </Grid>

                                          <Grid
                                            item
                                            md={12}
                                            lg={12}
                                            sm={12}
                                            xs={12}
                                          >
                                            {this.state.educationDetails
                                              .length > 0 &&
                                              this.state.educationDetails.map(
                                                (education, index) => (
                                                  <div className="professionCard">
                                                    <Grid container spacing={0}>
                                                      <Grid
                                                        item
                                                        md={4}
                                                        xs={8}
                                                        sm={8}
                                                        lg={4}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("Company Name")}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {
                                                              education.companyName
                                                            }
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={4}
                                                        xs={4}
                                                        sm={4}
                                                        lg={4}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("Role")}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {education.role}
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={4}
                                                        xs={8}
                                                        sm={8}
                                                        lg={4}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("Education")}
                                                          </p>
                                                          {this.state.schools.map(
                                                            (edit, index) => (
                                                              <>
                                                                {edit.id ===
                                                                  education.jobFunction && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "gray",
                                                                      paddingLeft:
                                                                        "8px",
                                                                      fontSize:
                                                                        "12px",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    {edit.name}
                                                                  </p>
                                                                )}
                                                              </>
                                                            )
                                                          )}
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={4}
                                                        xs={4}
                                                        sm={4}
                                                        lg={4}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("Industry")}
                                                          </p>
                                                          {this.state.industrysList.map(
                                                            (edit, index) => (
                                                              <>
                                                                {edit.id ===
                                                                  education.industry && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "gray",
                                                                      paddingLeft:
                                                                        "8px",
                                                                      fontSize:
                                                                        "12px",
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    {edit.name}
                                                                  </p>
                                                                )}
                                                              </>
                                                            )
                                                          )}
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={4}
                                                        xs={8}
                                                        sm={6}
                                                        lg={4}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("From Year")}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {education.fromYear}
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={3}
                                                        xs={4}
                                                        sm={5}
                                                        lg={3}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("To Year")}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {education.toYear}
                                                          </p>
                                                        </div>
                                                      </Grid>

                                                      <Grid
                                                        item
                                                        md={1}
                                                        xs={12}
                                                        sm={1}
                                                        lg={1}
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                        }}
                                                      >
                                                        <AiOutlineEdit
                                                          style={{
                                                            cursor: "pointer",
                                                            width: "25px",
                                                            height: "25px",
                                                            color: "#A00030",
                                                            float: "left",

                                                            //  paddingTop:"5px"
                                                          }}
                                                          onClick={() =>
                                                            this.editEducationDetails(
                                                              education.id
                                                            )
                                                          }
                                                        ></AiOutlineEdit>
                                                        <Delete
                                                          onClick={() =>
                                                            this.deleteProfessionDetails(
                                                              education.id
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                            width: "25px",
                                                            height: "25px",
                                                            color: "#A00030",
                                                          }}
                                                        />{" "}
                                                      </Grid>
                                                    </Grid>
                                                  </div>
                                                )
                                              )}
                                            {this.state.educationDetails
                                              .length == 0 && (
                                              <p>
                                                {t(
                                                  "No work experience details added yet"
                                                )}
                                              </p>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </>
                                  )}
                                {this.state.editEducation && (
                                  <>
                                    <div className="profileselectCard">
                                      <Grid item md={12} sm={12} xs={12}>
                                        <div
                                          className=""
                                          style={{
                                            float: "left",
                                            margin: "20px",
                                          }}
                                        >
                                          <button onClick={this.backEducation}>
                                            {t("Back")}
                                          </button>
                                        </div>
                                      </Grid>

                                      <BlockUi
                                        tag="div"
                                        blocking={this.state.blocking}
                                        message={t("Loading, please wait")}
                                        color="#A00030"
                                      >
                                        <Grid container spacing={0}>
                                          <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            sm={12}
                                            lg={12}
                                          >
                                            <h6>
                                              {t("Add Work Experience Details")}
                                            </h6>

                                            {this.state.inputs.length > 0 &&
                                              this.state.inputs.map(
                                                (entry, index) => (
                                                  <div>
                                                    <Grid container spacing={0}>
                                                      <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                        sm={12}
                                                        lg={12}
                                                      >
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            fontSize: "35px",
                                                            float: "right",
                                                            marginTop: "12px",
                                                          }}
                                                          class=""
                                                          onClick={() =>
                                                            this.removeTranslation(
                                                              entry,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          -{" "}
                                                        </button>
                                                      </Grid>{" "}
                                                    </Grid>
                                                    <Grid container spacing={1}>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6>
                                                          {t("Company Name")}
                                                        </h6>
                                                        <TextField
                                                          style={{
                                                            borderRadius:
                                                              "10px",
                                                            marginBottom: "8px",
                                                          }}
                                                          fullWidth
                                                          variant="outlined"
                                                          type="text"
                                                          required=""
                                                          name="companyName"
                                                          onInput={(e) =>
                                                            (e.target.value =
                                                              e.target.value.slice(
                                                                0,
                                                                40
                                                              ))
                                                          }
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "companyName"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].companyName
                                                          }
                                                        />
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6>{t("Role")}</h6>
                                                        <TextField
                                                          onInput={(e) =>
                                                            (e.target.value =
                                                              e.target.value.slice(
                                                                0,
                                                                40
                                                              ))
                                                          }
                                                          style={{
                                                            borderRadius:
                                                              "10px",
                                                            marginBottom: "8px",
                                                          }}
                                                          fullWidth
                                                          variant="outlined"
                                                          type="text"
                                                          required=""
                                                          name="role"
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "role"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].role
                                                          }
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1}>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6
                                                          style={{
                                                            paddingLeft: "8px",
                                                          }}
                                                        >
                                                          {t("From Year")}
                                                        </h6>
                                                        {/* <TextField
                                                          style={{
                                                            borderRadius:
                                                              "10px",
                                                            paddingLeft: "2px",
                                                            marginBottom: "8px",
                                                          }}
                                                          fullWidth
                                                          variant="outlined"
                                                          type="number"
                                                          required=""
                                                          name="fromYear"
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "fromYear"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].fromYear
                                                          }
                                                        /> */}

                                                        <TextField
                                                          id="dropdown"
                                                          select
                                                          label=""
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].fromYear
                                                          }
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "fromYear"
                                                            )
                                                          }
                                                          variant="outlined"
                                                          fullWidth={true}
                                                        >
                                                          {this.state.startYear.map(
                                                            (option, index) => (
                                                              <MenuItem
                                                                key={option.id}
                                                                value={
                                                                  option.id
                                                                }
                                                              >
                                                                {option.name}
                                                              </MenuItem>
                                                            )
                                                          )}
                                                        </TextField>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6>{t("To Year")}</h6>
                                                        {/* <TextField
                                                          style={{
                                                            borderRadius:
                                                              "10px",
                                                            paddingLeft: "2px",
                                                            marginBottom: "8px",
                                                          }}
                                                          fullWidth
                                                          variant="outlined"
                                                          type="number"
                                                          required=""
                                                          name="toYear"
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "toYear"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                            index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].toYear
                                                          }
                                                        /> */}

                                                        <TextField
                                                          id="dropdown"
                                                          select
                                                          label=""
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].toYear
                                                          }
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "toYear"
                                                            )
                                                          }
                                                          variant="outlined"
                                                          fullWidth={true}
                                                        >
                                                          {this.state.startYear.map(
                                                            (option, index) => (
                                                              <MenuItem
                                                                key={option.id}
                                                                value={
                                                                  option.id
                                                                }
                                                              >
                                                                {option.name}
                                                              </MenuItem>
                                                            )
                                                          )}
                                                        </TextField>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1}>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6>{t("Industry")}</h6>
                                                        <TextField
                                                          id="dropdown"
                                                          select
                                                          label=""
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "industry"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].industry
                                                          }
                                                          variant="outlined"
                                                          fullWidth={true}
                                                        >
                                                          {this.state.industrysList.map(
                                                            (option, index) => (
                                                              <MenuItem
                                                                key={option.id}
                                                                value={
                                                                  option.id
                                                                }
                                                              >
                                                                {option.name}
                                                              </MenuItem>
                                                            )
                                                          )}
                                                        </TextField>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        xs={6}
                                                        sm={6}
                                                        lg={6}
                                                      >
                                                        <h6>
                                                          {t("Job Functions")}
                                                        </h6>
                                                        <TextField
                                                          id="dropdown"
                                                          select
                                                          label=""
                                                          onChange={(e) =>
                                                            this.handleInputEducation(
                                                              e,
                                                              index,
                                                              "jobFunction"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frEducationName &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frEducationName[
                                                              index
                                                            ].jobFunction
                                                          }
                                                          variant="outlined"
                                                          fullWidth={true}
                                                        >
                                                          {this.state.schools.map(
                                                            (option, index) => (
                                                              <MenuItem
                                                                key={option.id}
                                                                value={
                                                                  option.id
                                                                }
                                                              >
                                                                {option.name}
                                                              </MenuItem>
                                                            )
                                                          )}
                                                        </TextField>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid
                                                      container
                                                      spacing={0}
                                                      style={{
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                        sm={12}
                                                        lg={12}
                                                      ></Grid>
                                                    </Grid>
                                                  </div>
                                                )
                                              )}
                                            <Grid container spacing={0}>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                {
                                                  <div className="profileFormBtn">
                                                    <button
                                                      style={{
                                                        borderRadius: "10px",
                                                        padding: "8px",
                                                      }}
                                                      onClick={
                                                        this.saveEducation
                                                      }
                                                    >
                                                      {t("Save")}
                                                    </button>
                                                  </div>
                                                }
                                              </Grid>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <button
                                                  style={{
                                                    cursor: "pointer",
                                                    marginTop: "20px",
                                                    float: "right",
                                                  }}
                                                  class=""
                                                  onClick={this.addTranslation}
                                                >
                                                  +
                                                </button>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </BlockUi>
                                    </div>
                                  </>
                                )}
                                {this.state.editEducationDetail && (
                                  <>
                                    <div className="profileselectCard">
                                      <Grid item md={12} sm={12} xs={12}>
                                        <div
                                          className=""
                                          style={{
                                            float: "left",
                                            margin: "20px",
                                          }}
                                        >
                                          <button onClick={this.backEducation}>
                                            {t("Back")}
                                          </button>
                                        </div>
                                      </Grid>

                                      <Grid container spacing={0}>
                                        <Grid
                                          item
                                          md={12}
                                          xs={12}
                                          sm={12}
                                          lg={12}
                                        >
                                          <h6 style={{ fontWeight: "bold" }}>
                                            {t("Edit Work Experience Details")}
                                          </h6>

                                          <div>
                                            <Grid container spacing={1}>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6>{t("Company Name")}</h6>
                                                <TextField
                                                  onInput={(e) =>
                                                    (e.target.value =
                                                      e.target.value.slice(
                                                        0,
                                                        40
                                                      ))
                                                  }
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editCompanyName"
                                                  value={
                                                    this.state.editCompanyName
                                                  }
                                                  onChange={this.handleForm}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6
                                                  style={{
                                                    paddingLeft: "8px",
                                                  }}
                                                >
                                                  {t("From Year")}
                                                </h6>
                                                {/* <TextField
                                                    style={{
                                                      borderRadius: "10px",
                                                      paddingLeft: "2px",
                                                      marginBottom: "8px",
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    type="number"
                                                    required=""
                                                    name="editFromYear"
                                                    value={
                                                      this.state.editFromYear
                                                    }
                                                    onChange={this.handleForm}
                                                  /> */}
                                                <TextField
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  id="dropdown"
                                                  select
                                                  label=""
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editFromYear"
                                                  value={
                                                    this.state.editFromYear
                                                  }
                                                  onChange={
                                                    this.handleFormDropDown
                                                  }
                                                >
                                                  {this.state.startYear.map(
                                                    (option, index) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}{" "}
                                                </TextField>
                                              </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6>{t("To Year")}</h6>
                                                {/* <TextField
                                                    style={{
                                                      borderRadius: "10px",
                                                      paddingLeft: "2px",
                                                      marginBottom: "8px",
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    type="number"
                                                    required=""
                                                    name="editToYear"
                                                    value={
                                                      this.state.editToYear
                                                    }
                                                    onChange={this.handleForm}
                                                  /> */}
                                                <TextField
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  id="dropdown"
                                                  select
                                                  label=""
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editToYear"
                                                  value={this.state.editToYear}
                                                  onChange={
                                                    this.handleFormDropDown
                                                  }
                                                >
                                                  {this.state.startYear.map(
                                                    (option, index) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}{" "}
                                                </TextField>
                                              </Grid>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6>Role</h6>
                                                <TextField
                                                  onInput={(e) =>
                                                    (e.target.value =
                                                      e.target.value.slice(
                                                        0,
                                                        40
                                                      ))
                                                  }
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editRole"
                                                  value={this.state.editRole}
                                                  onChange={this.handleForm}
                                                />
                                              </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6>{t("Industry")}</h6>
                                                <TextField
                                                  id="dropdown"
                                                  select
                                                  label=""
                                                  name="editIndustry"
                                                  value={
                                                    this.state.editIndustry
                                                  }
                                                  onChange={
                                                    this.handleFormDropDown
                                                  }
                                                  variant="outlined"
                                                  fullWidth={true}
                                                >
                                                  {this.state.industrysList.map(
                                                    (option, index) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </TextField>
                                              </Grid>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <h6>{t("Job Functions")}</h6>
                                                <TextField
                                                  id="dropdown"
                                                  select
                                                  label=""
                                                  name="editJobFunction"
                                                  value={
                                                    this.state.editJobFunction
                                                  }
                                                  onChange={
                                                    this.handleFormDropDown
                                                  }
                                                  variant="outlined"
                                                  fullWidth={true}
                                                >
                                                  {this.state.schools.map(
                                                    (option, index) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </TextField>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              container
                                              spacing={0}
                                              style={{ marginTop: "5px" }}
                                            ></Grid>
                                          </div>

                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              md={6}
                                              xs={6}
                                              sm={6}
                                              lg={6}
                                            >
                                              {
                                                <div className="profileFormBtn">
                                                  <button
                                                    style={{
                                                      borderRadius: "10px",
                                                      padding: "8px",
                                                    }}
                                                    onClick={
                                                      this.editEducationList
                                                    }
                                                  >
                                                    {t("Update")}
                                                  </button>
                                                </div>
                                              }
                                            </Grid>
                                            <Grid
                                              item
                                              md={6}
                                              xs={6}
                                              sm={6}
                                              lg={6}
                                            ></Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 2 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Friends")}
                          </p>
                        </Grid>
                        {this.props.FriendsList &&
                          this.props.FriendsList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                friendId={data.userId}
                                fUserId={data.userProfile.userId}
                                image={data.profilePhoto}
                                name={`${data.userProfile.firstName} ${data.userProfile.lastName}`}
                                Dname={data.userProfile.displayName}
                                smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.FriendsList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No friends available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 3 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Blocked User")}
                          </p>{" "}
                        </Grid>
                        {this.props.blockedUserList &&
                          this.props.blockedUserList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                tabIndex={"BlockedUser"}
                                friendId={data.blockUserId}
                                fUserId={data.blockUserId}
                                image={data.profilePhoto}
                                name={data.userName}
                                Dname={data.userName}
                                // smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.blockedUserList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No More Blocked List Available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : (
                    (this.state.tabIndex === 3 ||
                      this.state.tabIndex === 4) && (
                      <div style={{ marginTop: "5px" }}>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <MyCommunity
                              type={
                                this.state.tabIndex === 3 ? "Feed" : "BookMark"
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* </CardContent>
        </Card> */}
      </Container>
    );
  }

  handleInputEducation(event, index, type) {
    if (type == "companyName") {
      if (event.target.value == "") {
        this.setState({
          companyNameValue: false,
        });
      } else {
        this.setState({
          companyNameValue: true,
        });
      }
    } else if (type == "fromYear") {
      if (event.target.value == "") {
        this.setState({
          fromYearValue: false,
        });
      } else {
        this.setState({
          fromYearValue: true,
        });
      }
    } else if (type == "role") {
      if (event.target.value == "") {
        this.setState({
          roleValue: false,
        });
      } else {
        this.setState({
          roleValue: true,
        });
      }
    } else if (type == "toYear") {
      if (event.target.value == "") {
        this.setState({
          toYearValue: false,
        });
      } else {
        this.setState({
          toYearValue: true,
        });
      }
    } else if (type == "industry") {
      if (event.target.value == "") {
        this.setState({
          industryValue: false,
        });
      } else {
        this.setState({
          industryValue: true,
        });
      }
    } else if (type == "jobFunction") {
      if (event.target.value == "") {
        this.setState({
          jobFunctionValue: false,
        });
      } else {
        this.setState({
          jobFunctionValue: true,
        });
      }
    }

    console.log("inputs values");
    console.log(event);

    var newEducations = this.state.frEducationName;
    console.log("type inputs values", type);
    console.log("type inputs event", event.target.value);
    if (newEducations[index]) {
      if (type == "companyName") {
        event.preventDefault();
        event.stopPropagation();
        if (
          /^[A-Za-z\s]+$/.test(event.target.value) ||
          event.target.value == ""
        ) {
          var obj = newEducations[index];
          obj.companyName = event.target.value;
          newEducations[index] = obj;
          console.log("inputs tanNumber", obj);
        }
      } else if (type == "fromYear") {
        console.log("inputs values 1");
        console.log(event.target.value);
        var obj = newEducations[index];
        obj.fromYear = event.target.value;
        newEducations[index] = obj;
      } else if (type == "toYear") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.toYear = event.target.value;
        newEducations[index] = obj;
      } else if (type == "role") {
        console.log("inputs values 1");
        if (
          /^[A-Za-z\s]+$/.test(event.target.value) ||
          event.target.value == ""
        ) {
          console.log(event);
          var obj = newEducations[index];
          obj.role = event.target.value;
          newEducations[index] = obj;
        }
      } else if (type == "industry") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.industry = event.target.value;
        newEducations[index] = obj;
      } else if (type == "jobFunction") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        obj.jobFunction = event.target.value;
        newEducations[index] = obj;
      } else {
        event.preventDefault();
        event.stopPropagation();
        var obj = newEducations[index];
        obj.companyName = event.target.value;
        newEducations[index] = obj;
      }
    } else {
      if (type == "companyName") {
        event.preventDefault();
        event.stopPropagation();
        // if (/^[A-Za-z\s]+$/.test(event.target.value)) {
        //   var obj = newEducations[index];
        //   var obj = {
        //     companyName: event.target.value,
        //     fromYear: "",
        //     toYear: "",
        //     role: "",
        //     industry: "",
        //     jobFunction: "",
        //   };
        //   newEducations[index] = obj;
        // } else {
        var obj = newEducations[index];
        var obj = {
          companyName: event.target.value?.trim(),
          fromYear: "",
          toYear: "",
          role: "",
          industry: "",
          jobFunction: "",
        };
        newEducations[index] = obj;
        // }
      } else if (type == "fromYear") {
        var data = event.target.value;
        var obj = newEducations[index];
        var obj = {
          companyName: "",
          fromYear: data,
          toYear: "",
          role: "",
          industry: "",
          jobFunction: "",
        };
        console.log("inputsss");
        console.log(obj);
        newEducations[index] = obj;
      } else if (type == "toYear") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        // obj.toYear = event.target.value;
        var obj = {
          companyName: "",
          fromYear: "",
          toYear: event.target.value,
          role: "",
          industry: "",
          jobFunction: "",
        };
        newEducations[index] = obj;
      } else if (type == "role") {
        console.log("inputs values 1");
        console.log(event);
        if (/^[A-Za-z\s]+$/.test(event.target.value)) {
          var obj = newEducations[index];
          //  obj.role = event.target.value;
          var obj = {
            companyName: "",
            fromYear: "",
            toYear: "",
            role: event.target.value,
            industry: "",
            jobFunction: "",
          };
          newEducations[index] = obj;
        } else {
          var obj = newEducations[index];
          //  obj.role = event.target.value;
          var obj = {
            companyName: "",
            fromYear: "",
            toYear: "",
            role: "",
            industry: "",
            jobFunction: "",
          };
          newEducations[index] = obj;
        }
      } else if (type == "industry") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        //obj.industry = event.target.value;
        var obj = {
          companyName: "",
          fromYear: "",
          toYear: "",
          role: "",
          industry: "2",
          jobFunction: "",
        };
        newEducations[index] = obj;
      } else if (type == "jobFunction") {
        console.log("inputs values 1");
        console.log(event);
        var obj = newEducations[index];
        //obj.jobFunction = event.target.value;
        var obj = {
          companyName: "",
          fromYear: "",
          toYear: "",
          role: "",
          industry: "",
          jobFunction: event.target.value,
        };
        newEducations[index] = obj;
      }
    }
    this.setState({
      frEducationName: newEducations,
    });
    console.log("this.state.frEducationName");
    console.log(newEducation);
    console.log(this.state.frEducationName);
    //  this.setState({answers:answer_id.target.value})
  }
  handleFormDropDown = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleForm(event) {
    if (/^[A-Za-z\s]+$/.test(event.target.value) || event.target.value == "") {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleClickEdit() {
    this.setState({ savebtnState: false });
  }
  gotoPreference = () => {
    this.props.history.push("/my-preference");
  };

  gotoprofession() {
    this.props.history.push("/my-profession");
  }
  gotoBusinessProfile() {
    this.props.history.push("my-business-profile");
  }

  gotoeducation() {
    this.props.history.push("/my-education");
  }
  gotoValueMatch() {
    this.props.history.push("/value-match");
  }
  gotoprofile() {
    this.props.history.push("/my-profile");
  }

  gotoskills() {
    this.props.history.push("/my-skills");
  }

  saveEducation() {
    if (
      this.state.companyNameValue &&
      this.state.fromYearValue &&
      this.state.toYearValue &&
      this.state.roleValue &&
      this.state.industryValue &&
      this.state.jobFunctionValue
    ) {
      this.saveEducationDetails();
    } else {
      toast.warn(`${this.props.t("Please fill the all Filed..")}`);
    }

    // this.saveEducationDetails();
  }

  //

  saveEducationDetails() {
    this.setState({ blocking: true });
    var saveEducation = {
      education: this.state.frEducationName,
      firstTimeSetup: "true",
    };
    ProfileService.saveWorkExperienceDetails(saveEducation)
      .then((response) => {
        var result = response.data;

        var data = [];
        console.log(this.state.frEducationName);
        for (let i = 0; i < this.state.frEducationName.length; i++) {
          // data.push({
          //   educationName: "",
          //   fromYear: "",
          //   endYear: "",
          // });
          this.setState({
            frEducationName: data,
            editEducation: false,
            editEducationDetail: false,
            inputs: [0],
          });
          this.getEducationDetails();
        }
        toast.success(`${this.props.t("Saved successfully")}`);

        this.setState({ blocking: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ blocking: false });
      });
  }
  editEducationList() {
    if (
      this.state.editCompanyName.trim() === "" ||
      this.state.editRole.trim() === "" ||
      this.state.editFromYear === "" ||
      this.state.editToYear === "" ||
      this.state.editIndustry === "" ||
      this.state.editJobFunction === ""
    ) {
      toast.warning(`${this.props.t("Please fill the all Filed..")}`);
    } else {
      this.setState({ blocking: true });
      var saveEducation = {
        editId: this.state.editId,
        companyName: this.state.editCompanyName,
        role: this.state.editRole,
        fromYear: this.state.editFromYear,
        toYear: this.state.editToYear,
        jobFunction: this.state.editJobFunction,
        industry: this.state.editIndustry,
      };
      ProfileService.editWorkExperienceList(saveEducation)
        .then((response) => {
          var result = response.data;
          var data = [];
          this.setState({ blocking: false });
          this.getEducationDetails();
          toast.success(`${this.props.t("Updated Successfully")}`);
          this.setState({
            editEducationDetail: false,
          });
        })
        .catch((error) => {
          this.setState({ blocking: false });
          toast.error(`${this.props.t("Error to saving")}`);
          console.log(error);
        });
    }
  }

  getEducationDetails() {
    var saveEducation = {};
    ProfileService.getWorkExperienceDetails(saveEducation)
      .then((response) => {
        var result = response.data;

        console.log("education details");
        console.log(response.data.data);
        if (response.data.status === 1) {
          this.setState({
            educationDetails: response.data.data.workExperience,
          });
          var educationData = response.data.data.workExperience;

          // for (var j = 0; j < this.state.industrysList.length; j++) {
          //   for (var i = 0; i < educationData.length; i++) {
          //     if (
          //       this.state.industrysList[j].id === educationData[i].industry
          //     ) {
          //       if (educationData[i].industry === 18) {
          //         this.setState({
          //           //   businessProfile: true,
          //         });
          //         //  alert(this.state.businessProfile)
          //         // console.log("education1", educationData[i].industry)
          //       } else {
          //         this.setState({
          //           //   businessProfile: false,
          //         });
          //       }
          //     }
          //   }
          // }

          this.setState({
            educationData,
          });
          this.setState({ addButton: true });
          // Object.keys(educationData).map((k) => {
          //   console.log(k);
          //   console.log(k.toString());

          //   // this.setState({
          //   //   dynamicLangArray: [
          //   //     ...this.state.dynamicLangArray,
          //   //     k
          //   //   ]
          //   // });
          // });

          // let unique_dynamic_key = [... new Set(this.state.dynamicLangArray)];

          // unique_dynamic_key.map(k => {
          //   var educationName = "";
          //   var fromYear = "";
          //   var endYear = "";
          //   var title = "";
          //   var major = "";
          //   if (k in this.state.educationData) {
          //     console.log(k + " in announment");
          //     educationName = this.state.educationData[k].educationName;
          //     fromYear = this.state.educationData[k].fromYear;
          //     endYear = this.state.educationData[k].endYear;
          //     title = this.state.educationData[k].title;
          //     major = this.state.educationData[k].major;
          //   }

          //   var obj = {
          //     "educationName": educationName,
          //     "fromYear": fromYear,
          //     "endYear": endYear,
          //     "title": title,
          //     "major": major,
          //   };

          //   this.setState({
          //     frEducationName: [
          //       ...this.state.frEducationName,
          //       obj
          //     ]
          //   });
          // });

          if (this.state.frEducationName.length > 0) {
            this.setState({ inputs: [] });
            for (let k = 0; k < this.state.frEducationName.length; k++) {
              var input = 1;

              this.setState({
                inputs: [...this.state.inputs, input],
              });
            }
          }
        } else {
          this.setState({ educationDetails: [] });
          this.setState({ addButton: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getUpdatedFriendsList() {
    let getFriendsList = {};
    FriendsFinder.getFriendsList(getFriendsList)
      .then((response) => {
        var result = response.data;
        this.setState({
          friendsList: result.data.friendsList,
        });
        AuthService.storeFriendsList(result.data.friendsList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProfilePhoto(userPhoto) {
    this.setState({ userPhotoImage: `data:image/jpeg;base64,${userPhoto}` });
  }
  getUserCoverPhoto(coverPhoto) {
    this.setState({
      coverPhotoImage: `data:image/jpeg;base64,${coverPhoto}`,
      coverPhotoImageState: true,
    });
  }

  async getKnowledgePoints() {
    let getKnowledgePoints = { userId: this.props.loginDetails.uId };
    var response = ProfileService.getKPCache(getKnowledgePoints);
    if (response) {
      var result = response.data;
      if (result.data === null) {
        this.setState({ userKnowledgepoint: 0 });
      } else {
        this.setState({ userKnowledgepoint: result.data });
      }
    } else {
      ProfileService.getKnowledgePointByUser(getKnowledgePoints)
        .then((response) => {
          var result = response.data;
          if (result.data === null) {
            this.setState({ userKnowledgepoint: 0 });
          } else {
            ProfileService.setKPCache(getKnowledgePoints, response);
            this.setState({ userKnowledgepoint: result.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getallHobbies() {
    ProfileService.getallHobbies()
      .then((response) => {
        console.log(response);
        var result = response.data;
        AuthService.storeHobbiesList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        AuthService.storeGradeList(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFriendsList() {
    var friend = AuthService.getFriendsList();
    this.setState({
      FriendsList: friend,
    });
  }

  editEducation() {
    this.setState({ editEducation: true });
  }

  backEducation() {
    this.setState({
      editEducation: false,
      editEducationDetail: false,
      frEducationName: [],
      inputs: [1],
    });
  }
  editEducationDetails(id) {
    this.setState({ editEducationDetail: true });
    for (var i = 0; i < this.state.educationDetails.length; i++) {
      if (this.state.educationDetails[i].id == id) {
        console.log("Education", this.state.educationDetails[i].id);
        this.setState({
          editId: this.state.educationDetails[i].id,
          editCompanyName: this.state.educationDetails[i].companyName,
          editFromYear: this.state.educationDetails[i].fromYear,
          editToYear: this.state.educationDetails[i].toYear,
          editRole: this.state.educationDetails[i].role,
          editJobFunction: this.state.educationDetails[i].jobFunction,
          editIndustry: this.state.educationDetails[i].industry,
        });
      }
    }
  }
  deleteProfessionDetails(id) {
    var data = {
      deleteId: id,
    };
    ProfileService.deleteWorkExperienceDetails(data)
      .then((response) => {
        toast.success(`${this.props.t("Deleted successfully")}`);

        console.log(response);
        this.getEducationDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  console.log("Loading Notification Props");
  console.log(state.loginDetailsApi.loginDetails);

  return {
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    industryList: state.commonApi.industrylist.data,
    blockedUserList: state.commonApi.blockedUserList,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(ProfessionContainer)));
