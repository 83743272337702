import React, { Component } from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import EditableTextField from "../../components/EditableTextField";
import EditableDropdown from "../../components/EditableDropdown";
import AuthService from "../../session/AuthService";
import ProfileService from "../../api/ProfileService";
import MyCourseService from "../../api/MyCourseService";
import { toast, ToastContainer } from "react-toastify";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import moment from "moment";
import EditableDate from "../../components/EditableDatePicker";
import { withRouter } from "react-router";
import BlockUi from "react-block-ui";
import LoadService from "../../utils/LoadService";
import { storeLoginDetails } from "../../redux/actions/loginDetailsActions";
import * as microsoftTeams from "@microsoft/teams-js";
import { connect } from "react-redux";
import FindFriendsFilter from "../../api/FindFriendsFilter";
import "../css/mylike.css";
import { FiTwitter } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import TagMenuProfile from "../../components/TagMenuProfile";
import { VscCheck } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import EditableTextFieldLinkedIn from "../../components/EditableTextFieldLinkedIn";
import EditableTextFieldFacebook from "../../components/EditableTextFieldFacebook";
import EditableTextFieldTwitter from "../../components/EditableTextFieldTwitter";
import { AiOutlineEdit } from "react-icons/ai";
import EditableTextFieldInsta from "../../components/EditableTextFieldInsta";
import ReferralModel from "../../components/ReferralModel";
class ProfileDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactNumber: "",
      schoolId: "",
      schools: [],

      disbaledTagMenu: false,
      disbaledTagMenuLearn: false,
      disbaledTagMenuTeach: false,
      TagMenuValue: null,
      disabledSaveBtn: true,
      age: 0,
      userProfile: {},
      langList: [],
      semail: "",
      gender: "",
      firstLanguage: "",
      flanguages: "",
      joinyear: "",
      joinYear: "",
      liveyear: "",
      liveYear: "",
      schoolList: [],
      fromCountry: [],
      areaNames: [],
      linkedInValid: true,
      genderList: [
        { id: "male", name: this.props.t("Male") },
        { id: "female", name: this.props.t("Female") },
        { id: "other", name: this.props.t("Other") },
      ],
      subjectName: [],
      learningTeachingSubjectName: [],
      teachingSubjectName: [],
      editProfile: false,
      gradeList: [],
      grade: "",
      curriculumList: [],
      curriculamId: "",
      trackList: [],
      country: [],
      trackId: "",
      savebtnState: false,
      dName: "",
      firstName: "",
      lastName: "",
      countryId: "",
      location: "",
      blocking: false,
      professionID: false, //new
      companyName: "", //new
      areaOfInterest: false, //new
      industryId: false, //new
      industrysList: [],
      areaofinterestsList: [],
      ShortBio: "", //new
      linkedin: "", //new
      facebook: "", //new
      twitter: "", //new
      title: "", //new
      instagram: "",
      // profession: [
      //   {
      //     id: 1,
      //     name: "Salaried",
      //   },
      //   {
      //     id: 2,
      //     name: "Self-employeed",
      //   },
      // ],
      areaofInterestList: [],
      validLinkedIn: "",
      validFacebook: "",
      validTwitter: "",
      profession: "",
      professionList: [],
      userTag: false,
      workSpaceName: "",
      workSpaceId: 0,
      validInstagram: "",
    };
    this.block = this.block.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.backProfile = this.backProfile.bind(this);
    this.handleClickTagMenuEdit = this.handleClickTagMenuEdit.bind(this);
    this.getSelectedLanguage = this.getSelectedLanguage.bind(this);
    this.storeUserData = this.storeUserData.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.getGradeDetails = this.getGradeDetails.bind(this);
    this.getLanguageNameAlone = this.getLanguageNameAlone.bind(this);
    this.getSubjectNameAlone = this.getSubjectNameAlone.bind(this);
    this.getAreaofInterestId = this.getAreaofInterestId.bind(this);
    this.getlearningSubjectId = this.getlearningSubjectId.bind(this);
    this.getteachingSubjectId = this.getteachingSubjectId.bind(this);
    this.getProfileValues = this.getProfileValues.bind(this);
    this.getTrackAndCurriculum = this.getTrackAndCurriculum.bind(this);
    this.getCuriculumDetails = this.getCuriculumDetails.bind(this);
    this.gettrackDetails = this.gettrackDetails.bind(this);
    this.getOldLanguageList = this.getOldLanguageList.bind(this);
    this.onDataCheck = this.onDataCheck.bind(this);
    this.onDataCheckFacebook = this.onDataCheckFacebook.bind(this);
    this.onDataCheckTwitter = this.onDataCheckTwitter.bind(this);
    this.getOldLearningSubjectList = this.getOldLearningSubjectList.bind(this);
    this.getOldTeachingSubjectList = this.getOldTeachingSubjectList.bind(this);
    this.handleClickTagMenuEditLearn =
      this.handleClickTagMenuEditLearn.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleClickTagMenuEditTeach =
      this.handleClickTagMenuEditTeach.bind(this);
    this.getGradebasedCurriculum = this.getGradebasedCurriculum.bind(this);
    this.getGradeByCurriculumId = this.getGradeByCurriculumId.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.getAllSchool = this.getAllSchool.bind(this);
    // this.getAllSchoolglobal=this.getAllSchoolglobal.bind(this);
    this.getIndustryDetails = this.getIndustryDetails.bind(this);
    this.getAllIndustry = this.getAllIndustry.bind(this);
    this.getIndustry = this.getIndustry.bind(this);

    this.getAllAreaofInterest = this.getAllAreaofInterest.bind(this);
    this.getSchoolDetails = this.getSchoolDetails.bind(this);
    this.getAreaofInterestDetails = this.getAreaofInterestDetails.bind(this);
    this.getGradeByCurriculumIdUpdate =
      this.getGradeByCurriculumIdUpdate.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallLanguage = this.getallLanguage.bind(this);
    this.getallSubject = this.getallSubject.bind(this);
    this.getTrackAndCurriculumList = this.getTrackAndCurriculumList.bind(this);
    this.getGrade = this.getGrade.bind(this);
    this.getGradeLists = this.getGradeLists.bind(this);
    this.getSchool = this.getSchool.bind(this);
    this.getAreaofInterest = this.getAreaofInterest.bind(this);
    this.getOldAreaOfInterest = this.getOldAreaOfInterest.bind(this);
    this.getAllCountry = this.getAllCountry.bind(this);
    this.getCountry = this.getCountry.bind(this);
    this.getProfessionDetails = this.getProfessionDetails.bind(this);
    this.getAllProfessionDetails = this.getAllProfessionDetails.bind(this);
    this.checkUserTag = this.checkUserTag.bind(this);
  }

  handleClickTagMenuEdit() {
    this.setState({
      disbaledTagMenu: false,
      savebtnState: false,
    });
  }

  handleClickTagMenuEditLearn() {
    this.setState({
      disbaledTagMenuLearn: false,
      savebtnState: false,
    });
  }

  handleClickTagMenuEditTeach() {
    this.setState({
      disbaledTagMenuTeach: false,
      savebtnState: false,
    });
  }

  componentDidMount() {
    // this.getAllCountry()
    this.getCountry();
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    let userData = this.props.loginDetails;
    this.setState({
      workSpaceId: this.props.defaultWorkSpaceType
        ? this.props.defaultWorkSpaceType
        : 0,
    });
    if (AuthService.isMSTeamsApp()) {
      microsoftTeams.appInitialization.notifySuccess();
    }

    var selected_date = moment(userData.dob).format("DD/MM/yyyy");
    //    var selected_date = moment(userData.dob).format("yyyy-MM-DD")
    let userProfile = userData;
    console.log("userprofile");
    console.log("**********userData", this.props.loginDetails);

    if (userData) {
      let age = "";
      if (userData.dob) {
        age = selected_date;
      }

      let sEmail = "";
      if (userData.secEmail) {
        sEmail = userData.secEmail;
      }

      let gender = "";
      if (userData.gender) {
        gender = userData.gender;
      }
      let location = "";
      if (userData.location) {
        location = userData.location;
      }
      let grade = "";
      if (userData.gradeId) {
        grade = userData.gradeId;
      }
      let firstLanguage = "";
      if (userData.fLanguage) {
        firstLanguage = userData.fLanguage;
      }
      let joinYear = "";
      if (userData.jYear) {
        joinYear = userData.jYear;
      }
      let liveYear = "";
      if (userData.lYear) {
        liveYear = userData.lYear;
      }

      let curriculumId = "";
      if (userData.cId) {
        curriculumId = userData.cId;
      }
      let trackId = "";
      if (userData.tId) {
        trackId = userData.tId;
      }
      let countryId = "";
      if (userData.countryId) {
        countryId = userData.countryId;
      }
      let dName = "";
      if (userData.dName) {
        dName = userData.dName;
      }

      let firstName = "";
      if (userData.fName) {
        firstName = userData.fName;
      }
      let contactNumber = "";
      if (userData.phoneNumber) {
        contactNumber = userData.phoneNumber;
      }
      let lastName = "";
      if (userData.lName) {
        lastName = userData.lName;
      }

      let schoolId = "";
      if (userData.sclId) {
        schoolId = userData.sclId;
      }

      console.log(userData);

      let companyName = "";
      if (userData.company_Id) {
        companyName = userData.company_Id;
      }

      let areaOfInterest = "";
      if (userData.area_of_interest) {
        areaOfInterest = userData.area_of_interest;
      }

      let ShortBio = "";
      if (userData.biography) {
        ShortBio = userData.biography;
      }

      let industry = "";
      if (userData.industry_Id) {
        industry = userData.industry_Id;
      }

      let profession = "";

      if (userData.profession_Id) {
        profession = userData.profession_Id;
      }
      let twitter = "";
      if (userData.twitter) {
        twitter = userData.twitter;
      }
      let linkedin = "";
      if (userData.linkedin) {
        linkedin = userData.linkedin;
      }
      let facebook = "";
      if (userData.facebook) {
        facebook = userData.facebook;
      }
      let instagram = "";
      if (userData.instagram) {
        instagram = userData.instagram;
      }
      let title = "";
      if (userData.title) {
        title = userData.title;
      }
      this.getProfileValuesV2(
        companyName,
        profession,
        industry,
        countryId,
        ShortBio,
        areaOfInterest,
        twitter,
        linkedin,
        facebook,
        title,
        instagram
      );

      this.getProfileValues(
        userProfile,
        age,
        countryId,
        sEmail,
        gender,
        grade,
        location,
        firstLanguage,
        joinYear,
        liveYear,
        curriculumId,
        trackId,
        dName,
        firstName,
        lastName,
        schoolId,
        contactNumber
      );
    }

    let schoolList = [];
    var currentYear = new Date().getUTCFullYear();
    for (var i = 1980; i <= currentYear; i++) {
      var obj = {};
      obj["id"] = i;
      obj["name"] = i;
      schoolList.push(obj);
    }
    this.setState({ schoolList: schoolList });
    this.getallGrade();
    if (this.props.firstTime === true) {
      this.getTrackAndCurriculum();
      this.getAllSchool();
      this.getAllAreaofInterest(userData.profileAreaOfInterest);
      this.getAllIndustry();
      // this.getAllProfessionDetails();
      console.log(this.state.grade !== "");
    } else {
      this.getTrackAndCurriculumList();
      this.getSchool();
      this.getAreaofInterest(userData.profileAreaOfInterest);
      this.getIndustry();
      this.getProfessionDetails();
    }
  }

  initfunction() {
    let userData = this.props.loginDetails;
    //this.getallLanguage(userData.uLanguage);
    //this.getallSubject(userData.uLearn, userData.uTeach);
  }
  getAllCountry() {
    let getAllCountry = {};
    ProfileService.getAllCountry(getAllCountry)
      .then((response) => {
        var result = response.data;
        console.log("get all country by id", result.data);
        this.setState({ countryList: result.data });
        console.log("countryList", this.state.countryList);
        this.getCountry();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCountry() {
    var country = this.props.countyNameList;
    console.log("countryList", country);
    if (country) {
      var lList = [];
      for (var i = 0; i < country.length; i++) {
        var obj = {};
        obj["id"] = country[i].id;
        obj["name"] = country[i].nicename;
        lList.push(obj);
      }

      this.setState({ country: lList });
      console.log(lList);
    }
    console.log("country", this.state.country);
  }
  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async getProfileValuesV2(
    companyName,
    profession,
    industry,
    countryId,
    ShortBio,
    areaOfInterest,
    twitter,
    linkedin,
    facebook,
    title,
    instagram
  ) {
    await this.setState({
      companyName,
      profession: profession,
      industryId: industry,
      countryId: countryId,
      ShortBio: ShortBio,
      areaOfInterest: areaOfInterest,
      twitter: twitter,
      linkedin: linkedin,
      facebook: facebook,
      title: title,
      instagram: instagram,
    });
  }

  async getProfileValues(
    userProfile,
    age,
    countryId,
    email,
    gender,

    grade,
    location,
    firstLanguage,
    joinYear,
    liveYear,
    curriculumId,
    trackId,
    dName,
    firstName,
    lastName,
    schoolId,
    contactNumber
  ) {
    await this.setState({
      userProfile: userProfile,
      age: age,
      countryId: countryId,
      semail: email,
      gender: gender,
      grade: grade,

      location: location,
      firstLanguage: firstLanguage,
      flanguages: firstLanguage,
      joinYear: joinYear,
      joinyear: joinYear,
      liveYear: liveYear,
      liveyear: liveYear,
      curriculumId: curriculumId,
      trackId: trackId,
      dName: dName,
      firstName: firstName,
      lastName: lastName,
      schoolId: schoolId,
      contactNumber: contactNumber,
    });
  }
  checkUserTag = async (e) => {
    var obj = {
      userTag: e,
    };
    ProfileService.checkUserTag(obj)
      .then((response) => {
        var result = response.data;
        // this.setState({ savebtnState: true });
        console.log("uer tag check : ", response.data.data.userTag);
        //  this.setState(

        //     {
        //       userTag:response.data.data.userTag
        //     })

        this.setState({ userTag: response.data.data.userTag }, function () {
          console.log(this.state.userTag);
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in updating Profile");
        this.block(false);
      });
  };
  handleClickEdit() {
    this.setState({ savebtnState: false });
  }
  changePhoneNumberModel = () => {
    this.setState({
      phoneNumberModel: !this.state.phoneNumberModel,
    });
  };
  render() {
    const { t } = this.props;
    const { disbaledTagMenu } = this.state;
    const {
      fName,
      location,
      cId,
      countryId,
      dName,
      phoneNumber,
      lName,
      sclId,
      area_of_interest,
      biography,
      industry_Id,
      profession_Id,
      twitter,
      linkedin,
      facebook,
      title,
    } = this.props.loginDetails;
    console.log(fName, "fName");
    return (
      <div className="proileFormWrapper">
        <ToastContainer position="top-right" autoClose={5000} />
        {!this.state.editProfile && (
          <div className="">
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <p className="profileTitle">
                  {t("Hi")} {this.props.loginDetails.dName}
                </p>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <div className="" style={{ float: "right" }}>
                  <button onClick={this.editProfile}>
                    <AiOutlineEdit
                      style={{
                        cursor: "pointer",
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    {t("Edit Profile")}{" "}
                  </button>
                </div>
              </Grid>

              <Grid item md={6} sm={6} xs={6}>
                {/* <EditableTextField
                                label={t("First Name")}
                                value={this.state.firstName}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    this.setState({
                                        firstName: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("First Name")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {fName ? fName : ""}
                  </p>
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                {/* <EditableTextField
                                label={t("Last Name")}
                                value={this.state.lastName}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    this.setState({
                                        lastName: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Last Name")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {lName ? lName : ""}
                  </p>
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                {/* <EditableTextField
                                label={t("Display Name")}
                                value={this.state.dName}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    this.setState({
                                        dName: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Display Name")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {dName ? dName : ""}
                  </p>
                </div>
              </Grid>
              {/* <Grid item md={6} sm={12} xs={12}>
                       
                             <div className="">
                                <p style={{ color: "#000000", padding: "8px", cursor: "pointer" }}>{t("Gender")}</p>

                                {this.state.genderList.map((option, index) => (
                                    <>
                                        {this.state.gender == option.id &&
                                            <p style={{ color: "gray", paddingLeft: "8px", fontSize: "12px", cursor: "pointer" }}>{option.name}</p>
                                        }
                                    </>

                                ))}
                            </div>
                        </Grid> */}
              <Grid item md={6} sm={6} xs={6}>
                {/* <EditableDate
                                label={t("Date of birth")}
                                value={this.state.age}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    this.setState({
                                        age: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Date of birth")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {this.state.age}
                  </p>
                </div>
              </Grid>

              {/* //state */}
              <Grid item md={6} sm={6} xs={6}>
                {/* <EditableDropdownGrade
                                label={t("Grade")}
                                value={this.state.grade}
                                options={this.state.gradeList}
                                getProgramId={this.state.curriculumId}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    this.setState({
                                        grade: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Location")}
                  </p>

                  {/* {this.state.gradeList.map((option, index) => (
                    <>
                      {this.state.grade == option.id && ( */}
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {location}
                  </p>
                  {/* )}
                    </>
                  ))} */}
                </div>
              </Grid>

              {/* region/ */}
              <Grid item md={6} sm={6} xs={6}>
                {/* <EditableDropdown
                                label={t("Curriculum")}
                                value={this.state.curriculumId}
                                options={this.state.curriculumList}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    console.log(value);
                                    this.setState({
                                        curriculumId: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Country")}
                  </p>

                  {this.props.countyNameList.map((option, index) => (
                    <>
                      {countryId === option.id && (
                        <p
                          style={{
                            color: "gray",
                            paddingLeft: "8px",
                            fontSize: "12px",
                            cursor: "pointer",
                          }}
                        >
                          {option.nicename}
                        </p>
                      )}
                    </>
                  ))}
                </div>
              </Grid>
              {/* //new field */}
              <Grid item md={6} sm={6} xs={6}>
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Profession")}
                  </p>

                  {this.state.professionList.map((option, index) => (
                    <>
                      {profession_Id == option.id && (
                        <p
                          style={{
                            color: "gray",
                            paddingLeft: "8px",
                            fontSize: "12px",
                            cursor: "pointer",
                          }}
                        >
                          {option.name}
                        </p>
                      )}
                    </>
                  ))}
                </div>
              </Grid>

              {/* //new field */}
              {/* <Grid item md={6} sm={12} xs={12}> */}
              {/* <EditableTextField
                                label={t("Company Name")}
                                value={this.state.companyName}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    this.setState({
                                        companyName: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                                alphabet={true}
                            /> */}
              {/* <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Industry")}
                  </p>

                  {this.state.industrysList.map((option, index) => (
                    <>
                      {this.state.industryId == option.id && (
                        <p
                          style={{
                            color: "gray",
                            paddingLeft: "8px",
                            fontSize: "12px",
                            cursor: "pointer",
                          }}
                        >
                          {option.name}
                        </p>
                      )}
                    </>
                  ))}
                </div>
              </Grid> */}

              {/* { //new api } */}
              {/* <Grid item md={6} sm={12} xs={12}> */}
              {/* <EditableDropdown
                                label={t("Job Functions")}
                                value={this.state.schoolId}
                                options={this.state.schools}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    console.log(value);
                                    this.setState({
                                        schoolId: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}
              {/* <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Company Name/Institution")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {this.state.companyName}
                  </p>
                </div>
              </Grid> */}
              {/* new field */}
              {/* <Grid item md={6} sm={12} xs={12}> */}
              {/* <EditableDropdown
                                label={t("Area of Interest")}
                                value={this.state.areaOfInterest}
                                options={this.state.areaofinterestsList}
                                handleClickEdits={this.handleClickEdit}
                                getSelectedValue={(value) => {
                                    console.log(value);
                                    this.setState({
                                        areaOfInterest: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}
              {/* <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Job Functions")}
                  </p>

                  {this.state.schools.map((option, index) => (
                    <>
                      {this.state.schoolId == option.id && (
                        <p
                          style={{
                            color: "gray",
                            paddingLeft: "8px",
                            fontSize: "12px",
                            cursor: "pointer",
                          }}
                        >
                          {option.name}
                        </p>
                      )}
                    </>
                  ))}
                </div> */}
              {/*   */}
              {/* </Grid> */}

              {/* // new api */}
              <Grid item md={6} sm={6} xs={6}>
                {/* <EditableTextField
                                label={t("Title (Optional)")}
                                value={this.state.title}
                                handleClickEdits={this.handleClickEdit}
                                onChange={this.handleForm}
                                getSelectedValue={(value) => {
                                    this.setState({
                                        title: value,
                                        disabledSaveBtn: false,
                                    });
                                }}
                            /> */}

                {/* <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Ttile (Optional)")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {this.state.title}
                  </p>
                </div> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Area of Interest")}
                  </p>
                  {this.state.areaNames.map((tag, i) => (
                    <p
                      style={{
                        color: "gray",
                        paddingLeft: "8px",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                    >
                      {(i ? "," : "") + tag}
                    </p>
                  ))}
                </div>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                {/* <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={9}>
                                    <EditableTextField
                                        label={t("LinkedIn")}
                                        value={this.state.linkedin}
                                        onChange={this.handleForm}
                                        handleClickEdits={this.handleClickEdit}
                                        getSelectedValue={(value) => {
                                            this.setState({
                                                linkedin: value,
                                                disabledSaveBtn: false,
                                            });
                                        }}
                                    />
                                   
                                </Grid>

                                <Grid item xs={1}></Grid>
                                <Grid item xs={1}>
                                    <FaLinkedinIn
                                        style={{ width: "20px", marginTop: "20px", height: "58px", color: "#9c1c28" }}
                                    />
                                </Grid>
                            </Grid> */}

                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("LinkedIn")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {linkedin}
                  </p>
                </div>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Twitter")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {twitter}
                  </p>
                </div>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Instagram")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {this.state.instagram}
                  </p>
                </div>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                {/* <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={9}>
                                    <EditableTextField
                                        label={t("FaceBook")}
                                        value={this.state.facebook}
                                        handleClickEdits={this.handleClickEdit}
                                        onChange={this.handleForm}
                                        getSelectedValue={(value) => {
                                            this.setState({
                                                facebook: value,
                                                disabledSaveBtn: false,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={1}>
                                    <FiFacebook
                                        style={{ width: "24px", marginTop: "24px", height: "52px", color: "#9c1c28" }}
                                    />
                                </Grid>
                            </Grid> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Facebook")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {facebook}
                  </p>
                </div>
              </Grid>

              {/* region/ */}
              <Grid item md={6} sm={12} xs={12}>
                {/* <div
                                className={
                                    disbaledTagMenu
                                        ? "editableTagMenu formFieldGroup disabledField"
                                        : "editableTagMenu formFieldGroup"
                                }
                            >
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={11}>
                                        <label>{t("Short Bio")}</label>
                                        <textarea
                                            className="formInputsDescriptions"
                                            name="ShortBio"
                                            rows="4"
                                            cols="50"
                                            value={this.state.ShortBio}
                                            disabled={disbaledTagMenu}
                                            onChange={this.handleForm}
                                        />
                                    </Grid>
                                    <Grid item xs={1}> */}
                {/* <IconButton
                      aria-label="Edit"
                      size="small"
                      onClick={this.handleClickTagMenuEdit}
                    >
                      <CreateIcon fontSize="inherit" />
                    </IconButton> */}
                {/* </Grid> */}
                <div className="">
                  <p
                    style={{
                      color: "#000000",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {t("What others want to see about you?")}
                  </p>
                  <p
                    style={{
                      color: "gray",
                      paddingLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {this.state.ShortBio}
                  </p>
                </div>
              </Grid>
              {/* </div>
                        </Grid> */}
            </Grid>
          </div>
        )}
        {this.state.editProfile && (
          <BlockUi
            tag="div"
            blocking={this.state.blocking}
            message={t("Loading, please wait")}
            color="#60c2e7"
          >
            <Grid container spacing={0}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="" style={{ float: "left" }}>
                  <button onClick={this.backProfile}>{t("Back")}</button>
                </div>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <p className="profileTitle">
                  {/* {t("Hi")} {this.props.loginDetails.dName} */}
                </p>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <EditableTextField
                  label={t("First Name") + " *"}
                  value={this.state.firstName}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    this.setState({
                      firstName: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <EditableTextField
                  label={t("Last Name") + " *"}
                  value={this.state.lastName}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    this.setState({
                      lastName: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
      

              <EditableTextField
  acceptSpecialCharacter={true}
  label={t("Display Name") + " *"}
  value={this.state.dName}
  userTag={this.state.userTag}
  handleClickEdits={this.handleClickEdit}
  checkUserTag={(e) => this.checkUserTag(e)}
  getSelectedValue={(value) => {
    // Filter out unwanted characters
    const filteredValue = value
      .split("")
      .filter((char) => {
        const charCode = char.charCodeAt(0);
        // Allow only alphanumeric (a-z, A-Z, 0-9) and underscore (_)
        return (
          (charCode >= 48 && charCode <= 57) || // 0-9
          (charCode >= 65 && charCode <= 90) || // A-Z
          (charCode >= 97 && charCode <= 122) || // a-z
          charCode === 95 // underscore (_)
        );
      })
      .join("");

    // Update state with the filtered value
    this.setState({
      dName: filteredValue,
      disabledSaveBtn: false,
    });
  }}
/>










































      
      
      
      
      
      
      
      
      
      
      
              </Grid>

              <Grid item md={6} sm={6} xs={6}>
                <EditableDate
                  label={t("Date of birth") + " *"}
                  value={this.state.age}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    this.setState({
                      age: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid>

              {/* //state */}
              <Grid item md={6} sm={6} xs={6}>
                <EditableTextField
                  label={t("Location") + " *"}
                  value={this.state.location}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    this.setState({
                      location: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
                {/* <EditableDropdownGrade
                  label={t("Location")}
                  value={this.state.grade}
                  options={this.state.gradeList}
                  getProgramId={this.state.curriculumId}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    this.setState({
                      grade: value,
                      disabledSaveBtn: false,
                    });
                  }}
                /> */}
              </Grid>

              {/* region/ */}
              <Grid item md={6} sm={6} xs={6}>
                <EditableDropdown
                  label={t("Country") + " *"}
                  value={this.state.countryId}
                  options={this.state.country}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    console.log(value);
                    this.setState({
                      countryId: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid>
              {/* //new field */}
              <Grid item md={6} sm={12} xs={12}>
                <EditableDropdown
                  label={t("Profession") + " *"}
                  value={this.state.profession}
                  options={this.state.professionList}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    console.log(value);
                    this.setState({
                      profession: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid>

              {/* <Grid item md={6} sm={12} xs={12}>
                <EditableDropdown
                  label={t("Industry")}
                  value={this.state.industryId}
                  options={this.state.industrysList}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    console.log(value);
                    this.setState({
                      industryId: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid> */}

              {/* { //new api } */}
              {/* <Grid item md={6} sm={12} xs={12}>
                <EditableTextField
                  label={t("Company Name")}
                  value={this.state.companyName}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    this.setState({
                      companyName: value,
                      disabledSaveBtn: false,
                    });
                  }}
                  // alphabet={true}
                />
              </Grid> */}
              {/* new field */}

              {/* <Grid item md={6} sm={12} xs={12}>
                <EditableDropdown
                  label={t("Job Functions")}
                  value={this.state.schoolId}
                  options={this.state.schools}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    console.log(value);
                    this.setState({
                      schoolId: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid> */}

              {/* // new api */}
              <Grid item md={6} sm={12} xs={12}>
                <label className="label-v2">
                  {t("Which topics and industries are you interested in ?") +
                    "  *"}
                </label>
                <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
                  <Grid item xs={11}>
                    <TagMenuProfile
                      allMenus={this.state.areaofInterestList}
                      addLabel={"Add"}
                      selectedOldName={this.state.subjectNames}
                      isTagMenuFilter={true}
                      value={"English"}
                      getSelectedMenus={(value) => {
                        this.setState({
                          areaOfInterest: value,
                          savebtnState: false,
                        });
                        this.getAreaofInterestId(value);

                        this.setState({
                          languages: value,
                          disabledSaveBtn: false,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={6} xs={6}>
                <label className="label-v2">Phone Number</label>
                <div className="d-flex">
                  <TextField
                    id="textField"
                    label=""
                    variant="outlined"
                    // onKeyPress={(e) => alert("Hai")}
                    // onInput={(e) => (e.target.value = e.target.value.slice(0, 30))}
                    value={this.state.contactNumber}
                    // onChange={this.handleChangeInput}
                    fullWidth={true}
                    disabled={true}
                  />
                  <button
                    className="ml-3 mr-3"
                    onClick={() => this.changePhoneNumberModel()}
                  >
                    {this.props.loginDetails &&
                    this.props.loginDetails?.phoneNumber?.length > 8
                      ? "Update"
                      : "Add"}
                  </button>
                  {this.state.phoneNumberModel && (
                    <ReferralModel
                      type="referral"
                      profile={true}
                      id={this.props.loginDetails.referralCode}
                      content={``}
                      handleClose={this.changePhoneNumberModel}
                      url={"http://yuukke.com/promotion.php"}
                    />
                  )}
                </div>
                {/* <Row style={{ width: "100%" }}>
                  <Col xs="12 " sm="12">
                    <Form.Group className="formInputs">
                      <label className="label-v2">{t("Phone Number")}</label>
                      <Form.Control
                        type="number"
                        maxLength="10"
                        onChange={this.handleForm}
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 15))
                        }
                        // required
                        placeholder={t("Phone Number")}
                        value={this.state.contactNumber}
                        name="contactNumber"
                        disabled={
                          this.props.loginDetails &&
                          this.props.loginDetails?.phoneNumber?.length > 8
                            ? true
                            : false
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
              </Grid>
              {this.props.userWorkSpaceList &&
                this.props.userWorkSpaceList?.length > 1 && (
                  <Grid item md={6} sm={12} xs={12}>
                    <label className="label-v2">
                      {t("Select Default Work Space")}
                    </label>
                    <Grid
                      container
                      spacing={1}
                      alignItems="flex-end"
                      wrap="nowrap"
                    >
                      <Grid item xs={11}>
                        <TextField
                          id="dropdown"
                          select
                          label=""
                          value={this.state.workSpaceId}
                          onChange={this.workSpaceDropDown}
                          variant="outlined"
                          fullWidth={true}
                        >
                          {this.props.userWorkSpaceList &&
                            this.props.userWorkSpaceList.map(
                              (option, index) => (
                                <MenuItem
                                  key={option.workSpaceName}
                                  value={option.workSpaceId}
                                >
                                  {option.workSpaceName}
                                </MenuItem>
                              )
                            )}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <EditableTextFieldLinkedIn
                      label={t("LinkedIn")}
                      value={this.state.linkedin}
                      onChange={this.handleForm}
                      onDataCheck={this.onDataCheck}
                      handleClickEdits={this.handleClickEdit}
                      getSelectedValue={(value) => {
                        this.setState({
                          linkedin: value,
                          disabledSaveBtn: false,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={1} alignItems="flex-start">
                    {this.state.validLinkedIn == "true" && (
                      <VscCheck
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#32CD32",
                        }}
                      />
                    )}

                    {this.state.validLinkedIn == "false" && (
                      <VscChromeClose
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#A00030",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <FaLinkedinIn
                      style={{
                        width: "20px",
                        marginTop: "20px",
                        height: "58px",
                        color: "#A00030",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.state.linkedin
                          ? window.open(this.state.linkedin, "_blank")
                          : {}
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <EditableTextFieldTwitter
                      label={t("Twitter")}
                      value={this.state.twitter}
                      onChange={this.handleForm}
                      onDataCheck={this.onDataCheckTwitter}
                      handleClickEdits={this.handleClickEdit}
                      getSelectedValue={(value) => {
                        this.setState({
                          twitter: value,
                          disabledSaveBtn: false,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={1} alignItems="flex-start">
                    {this.state.validTwitter == "true" && (
                      <VscCheck
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#32CD32",
                        }}
                      />
                    )}

                    {this.state.validTwitter == "false" && (
                      <VscChromeClose
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#A00030",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <FiTwitter
                      style={{
                        width: "23px",
                        marginTop: "23px",
                        height: "53px",
                        color: "#A00030",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.state.twitter
                          ? window.open(this.state.twitter, "_blank")
                          : {}
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <EditableTextFieldFacebook
                      label={t("FaceBook")}
                      value={this.state.facebook}
                      onDataCheck={this.onDataCheckFacebook}
                      handleClickEdits={this.handleClickEdit}
                      onChange={this.handleForm}
                      getSelectedValue={(value) => {
                        this.setState({
                          facebook: value,
                          disabledSaveBtn: false,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} alignItems="flex-start">
                    {this.state.validFacebook == "true" && (
                      <VscCheck
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#32CD32",
                        }}
                      />
                    )}

                    {this.state.validFacebook == "false" && (
                      <VscChromeClose
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#A00030",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <FiFacebook
                      style={{
                        width: "24px",
                        marginTop: "24px",
                        height: "52px",
                        color: "#A00030",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.state.facebook
                          ? window.open(this.state.facebook, "_blank")
                          : {}
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <EditableTextFieldInsta
                      label={t("Instagram")}
                      value={this.state.instagram}
                      onDataCheck={this.onDataCheckInsta}
                      handleClickEdits={this.handleClickEdit}
                      onChange={this.handleForm}
                      getSelectedValue={(value) => {
                        this.setState({
                          instagram: value,
                          disabledSaveBtn: false,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} alignItems="flex-start">
                    {this.state.validInstagram == "true" && (
                      <VscCheck
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#32CD32",
                        }}
                      />
                    )}

                    {this.state.validInstagram == "false" && (
                      <VscChromeClose
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#A00030",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <FaInstagram
                      style={{
                        width: "24px",
                        marginTop: "24px",
                        height: "52px",
                        color: "#A00030",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.state.instagram
                          ? window.open(this.state.instagram, "_blank")
                          : {}
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* region/ */}
              <Grid item md={6} sm={12} xs={12}>
                <div
                  className={
                    disbaledTagMenu
                      ? "editableTagMenu formFieldGroup disabledField"
                      : "editableTagMenu formFieldGroup"
                  }
                >
                  <Grid container spacing={0} alignItems="flex-end">
                    <Grid item xs={11}>
                      <label className="label-v2">
                        {" "}
                        {t("What others want to see about you?") + " * "}
                      </label>
                      <textarea
                        className="formInputsDescriptions"
                        name="ShortBio"
                        rows="4"
                        cols="50"
                        value={this.state.ShortBio}
                        disabled={disbaledTagMenu}
                        onChange={this.handleForm}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {/* <IconButton
                      aria-label="Edit"
                      size="small"
                      onClick={this.handleClickTagMenuEdit}
                    >
                      <CreateIcon fontSize="inherit" />
                    </IconButton> */}
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                {!this.state.savebtnState && (
                  <div className="profileFormBtn">
                    <button onClick={this.saveProfile}>{t("Save")}</button>
                  </div>
                )}
                {this.state.savebtnState && (
                  <p style={{ textAlign: "center", color: "green" }}>
                    {t("Saved")}
                  </p>
                )}
              </Grid>
            </Grid>
          </BlockUi>
        )}
      </div>
    );
  }
  workSpaceDropDown = (e) => {
    this.setState({
      workSpaceId: e.target.value,
    });
  };
  onDataCheck(key) {
    this.setState({ validLinkedIn: key });
  }

  onDataCheckFacebook(key) {
    this.setState({ validFacebook: key });
  }

  onDataCheckInsta = (key) => {
    this.setState({ validInstagram: key });
  };
  onDataCheckTwitter(key) {
    this.setState({ validTwitter: key });
  }

  storeUserData(userData) {
    this.setState({
      age: userData.dob,
      level: userData.gradeId,
      SecondaryEmail: userData.gradeId,
    });
  }

  editProfile() {
    this.setState({ editProfile: true });
  }

  backProfile() {
    this.setState({ editProfile: false });
    if (this.state.validLinkedIn == "false" && !this.state.linkedin == "") {
      this.setState({ linkedin: "" });
    } else if (
      this.state.validTwitter == "false" &&
      !this.state.twitter == ""
    ) {
      this.setState({ twitter: "" });
    } else if (
      this.state.validFacebook == "false" &&
      !this.state.facebook == ""
    ) {
      this.setState({ facebook: "" });
    }
  }

  saveProfile() {
    if (
      this.state.firstName.trim() === "" ||
      this.state.firstName === undefined
    ) {
      toast.warn(`${this.props.t("Please enter valid first name")}`);
    } else if (
      this.state.lastName.trim() === "" ||
      this.state.lastName === undefined
    ) {
      toast.warn(`${this.props.t("Please enter valid last name")}`);
    } else if (this.state.userTag === true) {
      toast.warn(`${this.props.t("This display name already exists")}`);
    } else if (
      this.state.dName.trim() === "" ||
      this.state.dName === undefined
    ) {
      toast.warn(`${this.props.t("Please enter valid display name")}`);
    } else if (this.state.age === "" || this.state.age === undefined) {
      toast.warn(`${this.props.t("Please enter valid date of birth")}`);
    } else if (
      this.state.location.trim() === "" ||
      this.state.location === undefined
    ) {
      toast.warn(`${this.props.t("Please enter a valid location")}`);
    } else if (
      this.state.countryId === "" ||
      this.state.countryId === undefined
    ) {
      toast.warn(`${this.props.t("Please choose a valid country")}`);
    } else if (
      this.state.profession === "" ||
      this.state.profession === undefined
    ) {
      toast.warn(`${this.props.t("Please choose a valid profession")}`);
    } else if (
      this.state.selectedAreaofInterestId == "" ||
      this.state.selectedAreaofInterestId == undefined
    ) {
      toast.warn(
        `${this.props.t("Please choose valid topics and  industries")}`
      );
    } else if (
      this.state.validLinkedIn == "false" &&
      !this.state.linkedin == ""
    ) {
      toast.warn(`${this.props.t("Enter valid Linked Url !")}`);
      // this.setState({linkedin:""})
    } else if (
      this.state.validTwitter == "false" &&
      !this.state.twitter == ""
    ) {
      toast.warn(`${this.props.t("Enter valid Twitter Url !")}`);
      // this.setState({twitter:""})
    } else if (
      this.state.validFacebook == "false" &&
      !this.state.facebook == ""
    ) {
      toast.warn(`${this.props.t("Enter valid Facebook Url !")}`);
      // this.setState({facebook:""})
    } else if (this.state.ShortBio == "" || this.state.ShortBio == undefined) {
      toast.warn(`${this.props.t("Please share something about you")}`);
    } else if (this.state.age?.length >= 10 && this.state.age?.length < 11) {
      let countyNameList = this.props.countyNameList;
      var countryName = countyNameList.find(
        (item) => item.id === this.state.countryId
      );
      let updateProfile = {
        firstTimeSetup: "true",
        dob: this.state.age,
        gradeId: this.state.grade,
        secondaryEmail: this.state.semail,
        gender: this.state.gender,
        languageId: [],
        firstLanguage: this.state.flanguages,
        teachingSubjectId: [],
        subjectId: [],
        joinYear: this.state.joinyear,
        liveYear: this.state.liveyear,
        curriculumId: this.state.curriculumId,
        trackId: this.state.trackId,
        displayName: this.state.dName.trim(),
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        schoolId: this.state.schoolId,
        biography: this.state.ShortBio, // new
        companyName: this.state.companyName, //new column
        industryId: this.state.industryId, // new column
        areaOfInterest: this.state.selectedAreaofInterestId, // new column
        professionId: this.state.profession, // new column
        linkedin: this.state.linkedin, // new column
        twitter: this.state.twitter, // new column
        facebook: this.state.facebook, // new column
        title: this.state.title, //new column
        location: this.state.location.trim(), //new column
        countryId: this.state.countryId,
        // phoneNumber: this.state.contactNumber,
        country: countryName?.nicename,
        workSpaceType: this.state.workSpaceId ? this.state.workSpaceId : 0,
        // defaultWorksSpaceName:
        //   this.state.workSpaceName === ""
        //     ? "Default"
        //     : this.state.workSpaceName,
      };
      if (this.state.instagram && this.state.validInstagram === "true") {
        updateProfile.instagram = this.state.instagram;
      }

      ProfileService.updateUserProfile(updateProfile)
        .then((response) => {
          var result = response.data;
          // this.setState({ savebtnState: true });
          AuthService.registerAuth("", result.data);
          this.props.storeLoginDetails(
            result.data,
            this.props.authUser.loginType
          );
          // LoadService.redirect(this.props.history, "/my-profile");
          // console.log("PROFILE DETAILS");
          // console.log(result.data);
          // this.block(false);

          if (this.props.firstTime === true) {
            LoadService.redirect(this.props.history, "/home");
            console.log("PROFILE DETAILS");
            console.log(result.data);
            this.block(false);
          } else {
            toast.success(`${this.props.t("Updated Successfully")}`);
            LoadService.redirect(this.props.history, "/my-profile");
            console.log("PROFILE DETAILS");
            console.log(result.data);
            this.block(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(`${this.props.t("Error in updating Profile")}`);
          this.block(false);
        });
    } else {
      toast.warn("Please enter valid Date of Birth");
    }
  }
  getSelectedLanguage(languages) {}
  async getLanguageNameAlone(languageList) {
    var arraySubjectNames = [];
    for (let i = 0; i < languageList?.length; i++) {
      arraySubjectNames.push(languageList[i].languageName);
    }

    await this.setState({ subjectName: arraySubjectNames });
  }

  async getAreaofInterestId(selectedareaName) {
    var areaofinterestid = [];
    if (
      this.state.areaofinterestData &&
      this.state.areaofinterestData?.length
    ) {
      for (let i = 0; i < this.state.areaofinterestData.length; i++) {
        for (let j = 0; j < selectedareaName.length; j++) {
          if (
            this.state.areaofinterestData[i].tagName === selectedareaName[j]
          ) {
            areaofinterestid.push(this.state.areaofinterestData[i].tagId);
          }
        }
      }
    }
    await this.setState({ selectedAreaofInterestId: areaofinterestid });

    //
  }

  async getSubjectNameAlone(subjectList) {
    var arraySubjectNames = [];
    for (let i = 0; i < subjectList?.length; i++) {
      arraySubjectNames.push(subjectList[i].subjectName);
    }

    await this.setState({ learningTeachingSubjectName: arraySubjectNames });
  }

  async getteachingSubjectId(selectedSubjectName) {
    var subjectId = [];
    if (this.props.subjectsList?.data?.length > 0) {
      if (
        this.props.subjectsList &&
        this.props.subjectsList.data &&
        this.props.subjectsList.data.length
      ) {
        for (let i = 0; i < this.props.subjectsList.data.length; i++) {
          for (let j = 0; j < selectedSubjectName.length; j++) {
            if (
              this.props.subjectsList.data[i].subjectName ===
              selectedSubjectName[j]
            ) {
              subjectId.push(this.props.subjectsList.data[i].subjectId);
            }
          }
        }
      }
      await this.setState({ selectedTeachingSubjectId: subjectId });
    }

    //
  }

  async getlearningSubjectId(selectedSubjectName) {
    var subjectId = [];
    if (this.props.subjectsList?.data?.length > 0) {
      for (let i = 0; i < this.props.subjectsList.data.length; i++) {
        for (let j = 0; j < selectedSubjectName.length; j++) {
          if (
            this.props.subjectsList.data[i].subjectName ===
            selectedSubjectName[j]
          ) {
            subjectId.push(this.props.subjectsList.data[i].subjectId);
          }
        }
      }
      await this.setState({ selectedLearningSubjectId: subjectId });
    }
  }

  getallGrade() {
    console.log(AuthService.getGradeList());
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        this.getGradeDetails(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getTrackAndCurriculum() {
    //this.props.getAllCommonCurriculumTrackList(true, this.props.personalLanguage)
    var langs = {
      lang: this.props.personalLanguage,
    };
    ProfileService.getTrackAndCurriculumData(langs)
      .then((response) => {
        var result = response.data;
        //AuthService.storeCurriculumTrackList(result.data)
        this.getCuriculumDetails(result.data.curriculum);
        this.gettrackDetails(result.data.track);
      })
      .catch((error) => {
        // console.log(error)
      });
    //
  }
  getGradebasedCurriculum() {
    if (this.props.loginDetails.gradeId !== "") {
      this.getGradeByCurriculumIdUpdate(this.props.loginDetails.cId);
    }
  }

  getTrackAndCurriculumList() {
    var result = this.props.curriculumtrackList;
    console.log("Co", result);
    this.getCuriculumDetails(result);
  }
  getGradeByCurriculumId(value) {
    this.setState({ grade: "" });
    console.log("getGradeByCurriculumId" + value);
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    console.log(getGradeByCurriculumId);
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        console.log(response.data.data.gradeListById);

        this.getGradeDetails(response.data.data.gradeListById);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllCuricullam() {
    ProfileService.getAllCurriculum()
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  getGradeByCurriculumIdUpdate(value) {
    console.log("getGradeByCurriculumId" + value);
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    console.log(getGradeByCurriculumId);
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        console.log(response.data.data.gradeListById);

        this.getGradeDetails(response.data.data.gradeListById);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  gettrackDetails(track) {
    if (track) {
      var lList = [];
      for (var i = 0; i < track?.length; i++) {
        var obj = {};
        obj["id"] = track[i].trackId;
        obj["name"] = track[i].trackName;
        lList.push(obj);
      }

      this.setState({ trackList: lList });
    } else {
      console.log("No track present");
    }
  }

  getCuriculumDetails(curriculumList) {
    console.log(curriculumList);
    if (curriculumList) {
      var lList = [];
      for (var i = 0; i < curriculumList?.length; i++) {
        var obj = {};
        obj["id"] = curriculumList[i].curriculumId;
        obj["name"] = curriculumList[i].curriculumName;
        lList.push(obj);
      }

      this.setState({ curriculumList: lList });
    } else {
      console.log("No curriculum present");
    }
  }

  getGradeDetails(langList) {
    if (langList) {
      var lList = [];
      for (var i = 0; i < langList?.length; i++) {
        var obj = {};
        obj["id"] = langList[i].gradeId;
        obj["name"] = langList[i].gradeName;
        lList.push(obj);
      }

      this.setState({ gradeList: lList });
    }
  }

  async getOldLanguageList(oldLanguage, result) {
    var subjectName = [];
    console.log("oldLanguage");
    console.log(result.data);
    console.log("this.state.subjectDetails");
    for (let i = 0; i < result.data?.length; i++) {
      for (let j = 0; j < oldLanguage.length; j++) {
        if (result.data[i].languageId === oldLanguage[j].languageId) {
          subjectName.push(result.data[i].languageName);
        }
      }
    }
    console.log(subjectName);
    await this.setState({ subjectNames: subjectName });
    this.getSubjectId(subjectName);
  }

  async getOldLearningSubjectList(oldLearningSubjects) {
    var subjectName = [];
    console.log("oldLearningSubjects");
    console.log(oldLearningSubjects);
    console.log("this.state.subjectDetails");
    console.log(this.props.subjectsList);
    if (this.props.subjectsList.data.length > 0) {
      for (let i = 0; i < this.props.subjectsList.data.length; i++) {
        for (let j = 0; j < oldLearningSubjects.length; j++) {
          if (
            this.props.subjectsList.data[i].subjectId ===
            oldLearningSubjects[j].subjectId
          ) {
            subjectName.push(this.props.subjectsList.data[i].subjectName);
          }
        }
      }
      console.log(subjectName);
      await this.setState({ learningsubjectNames: subjectName });
      this.getlearningSubjectId(subjectName);
    }
  }

  async getOldTeachingSubjectList(oldTeachingSubjects) {
    var subjectName = [];
    console.log("oldTeachingSubjects");
    console.log(oldTeachingSubjects);
    console.log("this.state.subjectDetails");
    console.log(this.props.subjectsList);
    if (this.props.subjectsList.data.length > 0) {
      for (let i = 0; i < this.props.subjectsList.data.length; i++) {
        for (let j = 0; j < oldTeachingSubjects.length; j++) {
          if (
            this.props.subjectsList.data[i].subjectId ===
            oldTeachingSubjects[j].teachingSubjectId
          ) {
            subjectName.push(this.props.subjectsList.data[i].subjectName);
          }
        }
      }
      console.log(subjectName);
      await this.setState({ teachingsubjectNames: subjectName });
      this.getteachingSubjectId(subjectName);
    }
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  getAllSchool() {
    console.log("getAllSchool");
    FindFriendsFilter.getAllSchool()
      .then((response) => {
        var result = response.data;
        this.getSchoolDetails(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSchoolDetails(schoolList) {
    console.log("schoolList" + schoolList);
    if (schoolList) {
      var lList = [];
      for (var i = 0; i < schoolList.length; i++) {
        var obj = {};
        obj["id"] = schoolList[i].schoolId;
        obj["name"] = schoolList[i].schoolName;
        lList.push(obj);
      }

      this.setState({ schools: lList });
      console.log(lList);
    }
    console.log(this.state.schools);
  }

  getAllIndustry() {
    ProfileService.getAllIndustry()
      .then((response) => {
        var result = response.data.data;
        this.getIndustryDetails(result);
        console.log("Id", result);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getGrade() {
    var result = this.props.languageList;
    console.log("List grade", this.props.languageList);
    this.getGradeLists(result);
  }
  getGradeLists(langList) {
    if (langList) {
      var lList = [];
      for (var i = 0; i < langList.length; i++) {
        var obj = {};
        obj["id"] = langList[i].gradeId;
        obj["name"] = langList[i].gradeName;
        lList.push(obj);
      }

      this.setState({ gradeList: lList });
    }
  }

  getSchool() {
    var schoolList = this.props.schoolList;
    console.log("schoolList" + schoolList);
    if (schoolList) {
      var lList = [];
      for (var i = 0; i < schoolList.length; i++) {
        var obj = {};
        obj["id"] = schoolList[i].schoolId;
        obj["name"] = schoolList[i].schoolName;
        lList.push(obj);
      }

      this.setState({ schools: lList });
      console.log(lList);
    }
    console.log(this.state.schools);
  }
  getIndustry() {
    var industryList = this.props.industryList;
    console.log("loginDetails", this.props.loginDetails);
    console.log(industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      this.setState({ industrysList: lList });
    }
  }

  getProfessionDetails() {
    var professionList = this.props.professionList;

    if (professionList) {
      var lList = [];
      for (var i = 0; i < professionList.length; i++) {
        var obj = {};
        obj["id"] = professionList[i].professionId;
        obj["name"] = professionList[i].professionName;
        lList.push(obj);
      }
      console.log("list", lList);

      this.setState({ professionList: lList });
    }
  }

  getAllProfessionDetails() {
    var profile = {};
    ProfileService.getProfessionDetails(profile)
      .then((response) => {
        var result = response.data.data;

        if (result) {
          var lList = [];
          for (var i = 0; i < result.length; i++) {
            var obj = {};
            obj["id"] = result[i].professionId;
            obj["name"] = result[i].professionName;
            lList.push(obj);
          }
          console.log("list", lList);
          this.setState({ professionList: lList });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAreaofInterest(areaOfInterest) {
    // var areaofinterestList = this.props.areaofinterestList
    // if (areaofinterestList) {
    //     var lList = [];
    //     for (var i = 0; i < areaofinterestList.length; i++) {
    //         var obj = {};
    //         obj["id"] = areaofinterestList[i].areaId;
    //         obj["name"] = areaofinterestList[i].areaName;
    //         lList.push(obj);
    //     }

    //     this.setState({ areaofinterestsList: lList });
    //     console.log(lList);

    // }
    if (areaOfInterest != undefined) {
      this.getOldAreaOfInterest(areaOfInterest, this.props.tagList.data);
    }
    this.setState({ areaofinterestData: this.props.tagList.data });
    this.getAreaofInterestDetails(this.props.tagList.data);
  }
  async getOldAreaOfInterest(oldreaofinterest, result) {
    var areaName = [];
    console.log("oldLanguage");

    console.log("this.state.subjectDetails");
    for (let i = 0; i < result?.length; i++) {
      for (let j = 0; j < oldreaofinterest.length; j++) {
        if (result[i].tagId === oldreaofinterest[j].areaOfInterest) {
          areaName.push(result[i].tagName);
        }
      }
    }
    console.log("this.state.subjectDetails", areaName);
    await this.setState({ subjectNames: areaName });
    await this.setState({ areaNames: areaName });
    console.log("this.state.subjectDetails", this.state.subjectNames);
    this.getAreaofInterestId(areaName);
  }
  async getIndustryDetails(industryList) {
    console.log("loginDetails", this.props.loginDetails);
    console.log(industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      await this.setState({ industrysList: lList });
      console.log(lList);
      console.log("industrysList");
      console.log(this.state.industrysList);
    }
  }

  getAllAreaofInterest(areaOfInterest) {
    let data = {
      data: "",
    };
    // ProfileService.getAreaOfInterest(data)
    //   .then((response) => {
    //     var result = response.data.data.user;
    //     this.setState({ areaofinterestData: result });
    //     if (areaOfInterest != undefined) {
    //       this.getOldAreaOfInterest(areaOfInterest, result);
    //     }
    //     this.getAreaofInterestDetails(result);
    //   })
    //   .catch((error) => {
    //     // console.log(error)
    //   });
    MyCourseService.getAllTags(data)
      .then((response) => {
        var result = response.data.data;
        this.setState({ areaofinterestData: result });
        if (areaOfInterest != undefined) {
          this.getOldAreaOfInterest(areaOfInterest, result);
        }
        this.getAreaofInterestDetails(result);
      })
      .catch((error) => {
        //console.log(error)
      });
  }

  async getAreaofInterestDetails(areaofinterestList) {
    console.log("areaofinterestsList", areaofinterestList);
    console.log(areaofinterestList);
    // if (areaofinterestList) {
    //     var lList = [];
    //     for (var i = 0; i < areaofinterestList.length; i++) {
    //         var obj = {};
    //         obj["id"] = areaofinterestList[i].areaId;
    //         obj["name"] = areaofinterestList[i].areaName;
    //         lList.push(obj);
    //     }

    //     await this.setState({ areaofinterestsList: lList });
    //     console.log(lList);

    //     console.log(this.state.areaofinterestsList);
    // }
    if (Array.isArray(areaofinterestList) && areaofinterestList) {
      var arrayAreaofinterest = [];
      for (let i = 0; i < areaofinterestList.length; i++) {
        arrayAreaofinterest.push(areaofinterestList[i].tagName);
      }

      await this.setState({ areaofInterestList: arrayAreaofinterest });
    }
  }

  getallLanguage(uLanguage) {
    this.getOldLanguageList(uLanguage, this.props.languageList);
    this.getLanguageNameAlone(this.props.languageList.data);
    this.setState({ subjectDetails: this.props.languageList.data });
    if (this.props.languageList.data) {
      var lList = [];
      for (var i = 0; i < this.props.languageList.data.length; i++) {
        var obj = {};
        obj["id"] = this.props.languageList.data[i].languageId;
        obj["name"] = this.props.languageList.data[i].languageName;
        lList.push(obj);
      }

      this.setState({ langList: lList });
    }
  }
  getFriendsList() {
    var friend = this.props.FriendsList;
    this.setState({
      FriendsList: friend,
    });
  }
  getallSubject(uLearn, uTeach) {
    this.getOldLearningSubjectList(uLearn);
    this.getOldTeachingSubjectList(uTeach);
    this.getSubjectNameAlone(this.props.subjectsList.data);
  }
}

const mapStateToProps = function (state) {
  return {
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    personalLanguage: state.profileValue.lang,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist.curriculum,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    industryList: state.commonApi.industrylist.data,
    regionList: state.commonApi.regionlist,
    areaofinterestList: state.commonApi.areaofinterestlist,
    professionList: state.commonApi.professionList,
    countyNameList: state.commonApi.countryName,
    // userWorkSpaceList: state.commonApi.userWorkSpaceList,
    userWorkSpaceList: state.loginDetailsApi.loginDetails?.userWorkSpaceList,
    defaultWorkSpaceType:
      state.loginDetailsApi.loginDetails?.defaultWorkSpaceType,
    workspaceListHeader: state.commonApi.workspaceListHeader,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(ProfileDetailsForm)));
