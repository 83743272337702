import React, { useEffect, useRef, useState } from "react";
import { BiSelectMultiple } from "react-icons/bi";
import {
  MdInfoOutline,
  MdOutlineAttachFile,
  MdOutlineFormatListBulleted,
  MdOutlineVerifiedUser,
} from "react-icons/md";
import {
  FaMobileAlt,
  FaRegAddressBook,
  FaRegIdBadge,
  FaTags,
} from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryListV2,
  getSubscriptionList,
} from "../../redux/actions/calendarAction";
import { IoLogoInstagram } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import AuthService from "../../session/AuthService";
import { getLogoutData } from "../../redux/actions/logoutActions";
import { Button, Card, Form, Modal } from "react-bootstrap";
import RazorPay from "../../components/RazorPay";
import { toast } from "react-toastify";
import ProfileService from "../../api/ProfileService";
import Swal from "sweetalert2";
import OtpInput from "../Login/LoginV2/OtpInput";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { Chip } from "@material-ui/core";
import StepperFileOption from "../Wizard/StepperFileOption";
import { API_URL } from "../../config/app-config";
import { getAllCommonLanguageList } from "../../redux/actions/commonApiActions";
import BlockUi from "react-block-ui";
import list from "../../images/icons/list.png";
import pin from "../../images/icons/pin.png";
import subscription from "../../images/icons/subscription.png";
import verified from "../../images/icons/verified.png";
import ReactPlayer from "react-player";

function ServiceLandingPage(props) {
  const menuOptions = [
    // {
    //   title: "Required Information",
    //   icon: <MdInfoOutline />,
    // },
    {
      title: "Fill Your Info",
      icon: list,
    },
    // {
    //   title: "Login Credentials",
    //   icon: <MdOutlineVerifiedUser />,
    // },
    {
      title: "Verify With OTP",
      icon: pin,
    },
    {
      title: "Subscribe",
      icon: subscription,
    },
    {
      title: "Get Started",
      icon: verified,
    },
  ];
  const challenges = [
    "Overwhelmed by scheduling? Tired of back-and-forth emails and missed appointments?",
    "Struggling to find consistent income? Is your income unpredictable and reliant on one-time clients?",
    "Feeling limited by your time? Wishing you could reach more people and make a bigger impact?",
  ];
  const solution = [
    "Effortless Appointment Scheduling: Yuukke's intuitive booking system automates your calendar, eliminating scheduling headaches.",
    "Recurring Revenue You Can Rely On: Build a predictable income stream with subscriptions, freeing you from the feast-or-famine cycle.",
    "Scale Your Impact: Reach a wider audience and share your expertise with the world through online courses, memberships, and group coaching.",
  ];
  const easySteps = [
    "Step 1: Craft Your Offer: Define your subscription packages and pricing.",
    "Step 2: Build Your Page: Create a stunning landing page with Yuukke's easy-to-use templates.",
    "Step 3: Promote & Grow: Share your page with your network and on social media.",
  ];
  const faq = [
    {
      question: "How does Yuukke handle my data?",
      answer:
        "Yuukke takes your data privacy seriously. We use secure encryption to protect your information and ensure it is not shared with third parties without your consent.",
    },
    {
      question: "Where is my data stored?",
      answer:
        "Your data is securely stored on our cloud servers, which comply with international data protection standards such as GDPR and CCPA.",
    },
    {
      question: "Can I export my data from Yuukke?",
      answer:
        "Yes, you can export your data anytime directly from your account settings. We believe in giving you full control over your information.",
    },
    {
      question: "What happens to my data if I cancel my subscription?",
      answer:
        "In case you decide to cancel your subscription, a refund will be processed within 30 days.",
    },
    {
      question: "Does Yuukke track my customers' information?",
      answer:
        "Yuukke only collects customer data necessary for scheduling and subscription management. All data is securely stored and is never used for marketing or sold to third parties.",
    },
    {
      question: "How do I update my data or profile information?",
      answer:
        "You can update your data or profile information anytime through the account settings section of your dashboard.",
    },
  ];

  const socialIcons = [
    {
      icon: <IoLogoInstagram className="svg-icons text-white" />,
      link: "https://www.instagram.com/yuukkeglobal/",
    },
    {
      icon: <FaLinkedin className="svg-icons text-white" />,
      link: "https://www.linkedin.com/company/yuukke/?original_referer=https%3A%2F%2Fyuukke.com%2F",
    },
  ];
  const requiredInformation = [
    "<b>Your Details:</b> Begin by providing your personal information, including your name, email, and other necessary details.",

    "<b>Authentication Details:</b> For added security, you'll need to authenticate your identity by providing authentication details.",

    "<b>Mobile Verification:</b> Next, verify your mobile number to ensure seamless communication and security.",

    "<b>Subscription Plan:</b> Choose the subscription plan that best suits your needs and proceed with payment.",

    "<b>Successfully Profile Created:</b> Congratulations! Your profile is now successfully created, and you can start using our services right away.",
  ];
  const image = [
    "https://corporate.yuukke.com/bcav2/auth/image/bed6e235-d4a5-409f-89d7-4d0491056dd3",
    "https://marketplace.yuukke.com/themes/yuukke/shop/assets/images/visa.jpg",
    "https://marketplace.yuukke.com/themes/yuukke/shop/assets/images/master%20card%20.png",
    "https://marketplace.yuukke.com/themes/yuukke/shop/assets/images/american_express.png",
  ];
  const quickLinks = [
    {
      title: "Become A Seller",
      link: "https://marketplace.yuukke.com/seller_register",
    },
    {
      title: "Become A Mentor",
      link: "https://yuukke.com/become-a-mentor/",
    },
    {
      title: "Become A Member",
      link: "https://login.yuukke.com/auth/realms/yuukke/protocol/openid-connect/registrations?client_id=iwnFrontend&response_type=code&scope=openid%20email&redirect_uri=https://connect.yuukke.com/&kc_locale=en",
    },
    {
      title: "Become A Service Provider",
      link: "https://connect.yuukke.com/service-directory",
    },

    {
      title: "Join Us",
      link: "https://yuukke.com/join-entrepreneurs-community/",
    },
    {
      title: "Blog",
      link: "https://yuukke.com/blog/",
    },
    {
      title: "Women Leaders 2023",
      link: "https://yuukke.com/women-leaders-2023/",
    },
    {
      title: "Events",
      link: "https://yuukke.com/#events",
    },
  ];
  const support = [
    {
      title: "Why Yuukke",
      link: "https://yuukke.com/#why-yuukke",
    },
    {
      title: "Get Involved",
      link: "https://yuukke.com/#get-involved",
    },
    {
      title: "What We Do",
      link: "https://yuukke.com/#what-we-do",
    },
    {
      title: "Meet & Greet",
      link: "https://yuukke.com/meet-greet/",
    },
  ];
  const yuukke = [
    {
      title: "About Us",
      link: "https://yuukke.com/about/",
    },
    {
      title: "Global Ambassadors",
      link: "https://yuukke.com/global-ambassadors/",
    },
  ];
  const resource = [
    {
      title: "Privacy Policy",
      link: "https://yuukke.com/wp-content/uploads/2022/12/Yuukke-Privacy-Policy.pdf",
    },
    {
      title: "Terms & Conditions",
      link: "https://yuukke.com/wp-content/uploads/2024/02/yuukke_tnc.pdf",
    },
    {
      title: "Contact Us",
      link: "https://yuukke.com/contact-us/",
    },
  ];
  const started = [
    {
      title: "Create Your Expert Profile and Meeting Preference",
      description:
        "Set up your expert profile and define your meeting preferences to showcase your skills and streamline client interactions",
    },
    {
      title: "Create Your Service and Offerings",
      description:
        "Define and list your services with clear offerings and details to attract your ideal clients",
    },
    {
      title: "Share your Personalized page and get started",
      description:
        "Share your personalized page with potential clients and start offering your services seamlessly.",
    },
  ];
  const uploadOptions = [
    {
      file: "https://customer-3uw4nujlakj1ub6w.cloudflarestream.com/5df53024f5a24a78bd50d60af3567a70/manifest/video.m3u8",
      image:
        "https://corporate.yuukke.com/bcav2/auth/image/ba05a8e9-d307-4993-a3cb-25e4290a10bc",
      steamStatus: true,
    },
  ];
  const { categoryList, subscriptionList } = useSelector(
    (state) => state.event
  );
  const [state, setState] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    about: "",
    serviceImage: null,
    category: [],
    languageList: [],
    try: 0,
    otp: "",
    otpId: "",
    directoryId: null,
    filterCategoryList: [],
    loader: false,
    paymentScreen: false,
    screen: "",
    subscriptionId: null,
    paymentAmount: "",
    activeMenu: 0,
    videoFile: false,
  });

  const optionsMenu = useRef();
  const dispatch = useDispatch();
  const { languagelist } = useSelector((state) => state.commonApi);

  const registerPage = () => {
    props.history.push("/service-directory");
  };
  useEffect(() => {
    dispatch(getSubscriptionList("service"));
    dispatch(getAllCommonLanguageList());
    dispatch(getCategoryListV2());
  }, []);

  useEffect(() => {
    if (categoryList) {
      const list = categoryList.map((item) => {
        return { ...item, select: false };
      });
      updateState("filterCategoryList", list);
    }
  }, [categoryList]);
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const sendOtp = (e, menu) => {
    // e.preventDefault();
    // setState((previous) => ({
    //   ...previous,
    //   activeMenu: state.activeMenu + 1,
    //   loader: false,
    // }));
    if (state.try === 4) {
      toast.warning("You tried maximum time ,please try latter !");
    } else if (state.phoneNumber.length < 6) {
      toast.warning("Enter valid phone number");
    } else {
      updateState("loader", true);
      let number = {
        type: "phone",
        phoneNumber: state.phoneNumber,
        optModelName: "Service",
      };
      ProfileService.sendOtp(number)
        .then((response) => {
          if (response.data.data?.id) {
            setState({
              ...state,
              otpId: response.data.data.id,
              loader: false,
              activeMenu: menu + 1,
              try: state.try + 1,
            });
          }
        })
        .catch((e) => {
          console.log("e", e);
          toast.error("Something went wrong");
          updateState("loader", false);
        });
    }
  };
  const verifyOtp = () => {
    if (state.otp.length !== 6) {
      toast.warning("Enter valid otp");
      return;
    }
    // updateState("loader", true);

    let number = {
      otp: state.otp,
      id: state.otpId,
    };
    // setState((previous) => ({
    //   ...previous,
    //   activeMenu: state.activeMenu + 1,
    //   loader: false,
    // }));
    ProfileService.validateUserOtp(number)
      .then((response) => {
        if (response.data.data === "VALID_OTP") {
          // updateDetails();
          setState((previous) => ({
            ...previous,
            activeMenu: state.activeMenu + 1,
            loader: false,
          }));
        } else {
          toast.error("Wrong otp");
          updateState("loader", false);
        }
      })
      .catch((e) => {
        toast.error("Something went wrong");
        updateState("loader", false);
      });
  };

  const updateDetails = (id) => {
    const formData = new FormData();
    formData.append("firstName", state.userName.trim());
    formData.append("lastName", state.userName.trim().substring(0, 1));
    formData.append("email", state.email);
    formData.append("phoneNumber", state.phoneNumber);

    formData.append(
      "serviceName",
      state.businessName ? state.businessName : state.userName.trim()
    );
    formData.append("description", state.about);
    const lang = state.languageList.map((item) => {
      return { language: item.label, id: item.value };
    });

    formData.append("language", JSON.stringify({ data: lang }));
    const selectedList = state.filterCategoryList.filter((item) => item.select);
    const cate = selectedList.map((item) => {
      return { primaryId: item.pId, categoryId: item.value };
    });

    formData.append("categoryV2", JSON.stringify({ data: cate }));

    formData.append("categoryId", selectedList[0]?.value);
    formData.append("categoryName", selectedList[0]?.label);

    if (state.serviceImage) {
      formData.append("businessLogov2", state.serviceImage[0]);
    }
    if (state.directoryId) {
      formData.append("directoryId", state.directoryId);
    }

    formData.append("subscriptionId", state.subscriptionId);
    formData.append("paymentId", id);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    ProfileService.directoryAndServiceRegister(formData, config)
      .then((response) => {
        if (response.data.status !== 1) {
          toast.error("Something went wrong , please try again later");
          setState({
            ...state,
            otpId: null,
            loader: false,
            otp: "",
            screen: "",
            subscriptionId: null,
          });
        } else {
          setState({
            ...state,
            otpId: null,
            loader: false,
            otp: "",
            screen: "successScreen",
            subscriptionId: null,
            activeMenu: state.activeMenu + 1,
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong , please try again later");
        setState({
          ...state,
          otpId: null,
          loader: false,
          otp: "",
          screen: "",
        });
      });
  };

  const serviceImageUpdate = (file) => {
    if (file) {
      updateState("serviceImage", file);
    }
  };
  const removeFiles = (name) => {
    updateState(name, null);
  };
  const loginYuukke = () => {
    AuthService.removeAuth();
    dispatch(getLogoutData());
    AuthService.triggerLogout();
    localStorage.setItem("SERVICE_REGISTER", "yes");
  };
  const makeCategoryChanges = (data) => {
    const list = state.filterCategoryList.map((item) => {
      if (item.value === data.value) {
        item.select = !data.select;
      }
      return item;
    });
    console.log("list", list);
    updateState("filterCategoryList", list);
  };
  const openPaymentPage = (prize, id) => {
    setState((previous) => ({
      ...previous,
      paymentAmount: prize,
      subscriptionId: id,
    }));
  };
  const updatePaymentStatus = (status, id) => {
    if (status === "dismiss") {
      Swal.fire({
        icon: "error",
        title: "Payment updating failed!",
        text: "If your money debited in bank account ,please contact our team  +91 04446314646",
        showConfirmButton: true,
      }).then(() => {
        setState((previous) => ({
          ...previous,
          paymentSuccess: false,
          paymentAmount: null,
          screen: "",
          subscriptionId: null,
          loader: false,
        }));
      });
    } else {
      updateState("loader", true);
      updateDetails(id);
    }
  };
  const handleButtonClick = () => {
    // Scroll to the next section
    optionsMenu.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const changeScreen = (e, menu) => {
    e.preventDefault();
    if (menu === 0) {
      const checkCategory = state.filterCategoryList.every((c) => !c.select);
      if (checkCategory) {
        toast.warning("Select your service related category");
        return;
      } else {
        // updateState("activeMenu", menu + 1);
        sendOtp(e, menu);
      }
    } else {
      updateState("activeMenu", menu + 1);
    }
  };
  const openPhone = (event, number) => {
    window.location.href = `tel:${number}`;
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClick = (event, email) => {
    window.location.href = `mailto:${email}`;
    event.preventDefault();
    event.stopPropagation();
  };
  // console.log("state", state);

  return (
    <div className="service-landing-wrapper">
      <header className="d-flex justify-content-between gap-1 service-wrap flex-wrap classNames pt-4 pb-4">
        <img
          src="https://corporate.yuukke.com/bcav2/auth/image/22cb040b-f51d-47e6-8588-42eac1d8c909"
          alt="header"
          className="w-auto"
          style={{
            height: "3rem",
          }}
        />
        <div className="d-flex align-items-center gap-1 ml-auto">
          <p
            onClick={(e) => openPhone(e, "917305888114")}
            className="c-pointer text-secondary"
            style={{ fontSize: "13px" }}
          >
            Reach us at : 044 4631 4646
          </p>
          <button onClick={loginYuukke}>Sign Up</button>
        </div>
      </header>
      <hr className="m-0" />
      <section className="service-wrap service-sec-1 mt-4">
        <div>
          <h2 style={{ maxWidth: "34rem" }}>
            Break Free from the Hourly Hustle – Unlock Limitless Opportunities
            and Transform Your Services with Yuukke!
          </h2>

          <p>
            Yuukke empowers experts like you to{" "}
            <b>Generate Recurring Revenue, </b>
            <b> Automate Your Schedule</b> and
            <b> Scale Your Impact.</b>
            <br />
            <br />
            Offer your services<b> On The Go</b>, through both our{" "}
            <b>Mobile App</b> and <b>Website</b>, for{" "}
            <b>Ultimate Convenience </b>
            and <b>Flexibility</b> - All designed to Empower your Personal and
            Professional Journey.
          </p>
          <button
            onClick={handleButtonClick}
            className="service-btn font-bold p-2 mt-4"
          >
            <FaRegIdBadge className="mr-2 svg-icons text-white" />
            Register Your Service Now
          </button>
        </div>
        <div>
          <img
            className="h-100 w-100"
            src="https://corporate.yuukke.com/bcav2/auth/image/134cf176-bb2f-4fb7-a970-87633dc2ce5e"
            alt="img"
          />
        </div>
      </section>

      <section className="mt-5 service-bg-gradient text-white">
        <div className="service-wrap p-3">
          <h2
            className="m-auto font-bold pt-3 text-center"
            style={{ maxWidth: "45rem" }}
          >
            Our Business Providers Offer a Variety of Services Across the
            Following Categories
          </h2>
          <ul className="d-flex flex-wrap gap-1 mt-5 mb-5 justify-content-center l-none">
            {state.filterCategoryList.map((data, i) => (
              <li
                onClick={() => handleButtonClick()}
                className="service-tag c-pointer"
              >
                <FaTags className="h-auto mr-2" style={{ width: "1rem" }} />
                {data.label}
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section
        className="service-wrap d-flex align-items-center justify-content-center flex-wrap"
        style={{ minHeight: "100vh", gap: "4rem" }}
      >
        <img
          alt=""
          src="https://corporate.yuukke.com/bcav2/auth/image/adbb7975-7bde-4722-b6b8-95303f50e243"
          style={{
            width: "auto",
            height: "32rem",
            borderRadius: "5px",
          }}
          className="mt-3"
        />{" "}
        <h2
          style={{ fontSize: "2rem", maxWidth: "30rem" }}
          className="font-bold mt-4"
        >
          Your Expertise Drives the Success. With Our Platform, We Connect You
          to Exciting Opportunities, Bringing Client Visions to Life, Quickly
          and Effortlessly!
        </h2>
      </section>

      {/* <section className="service-info-wrap service-wrap mb-5">
        <div className="relative">
          <section style={{ position: "sticky", top: 0, paddingTop: "7%" }}>
            <img
              alt=""
              src="https://corporate.yuukke.com/bcav2/auth/image/ba05a8e9-d307-4993-a3cb-25e4290a10bc"
              style={{
                width: "auto",
                height: "17rem",
                borderRadius: "5px",
              }}
            />
            <h2 style={{ fontSize: "2rem" }} className="font-bold mt-4">
              Your vision drives the dream. Our platform brings it to life
              effortlessly and fast.
            </h2>
            
          </section>
        </div>
        <div>
          <h6 className="mb-4">Your Challenges, Solved</h6>
          <ul className="d-flex flex-column  gap-1">
            {challenges.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
          <h6 className="mb-4">Why Yuukke is Your Solution</h6>
          <ul className="d-flex flex-column  gap-1">
            {solution.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
          <h6 className="mb-4">Get Started in 3 Easy Steps!</h6>
          <ul className="d-flex flex-column  gap-1">
            {easySteps.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      </section> */}
      <section className="service-wrap ">
        <h3 className="service-head-under mb-4">Pricing & Features</h3>
        <ul
          className="d-flex flex-wrap align-items-center p-0 pb-5 justify-content-center"
          style={{ gap: "2rem" }}
        >
          {subscriptionList.map((data, i) => (
            <li
              className="service-card l-none"
              key={i}
              style={{ width: "16rem" }}
              onClick={(e) => handleButtonClick()}
            >
              <section className="p-3">
                <h5 className="m-0 font-weight-bold mb-3">
                  {data.subscriptionName}
                </h5>
                <p className="f-12 mt-2 mb-2" style={{ minHeight: "3rem" }}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.subscribeDescription.replace(
                        /<p><\/p>/g,
                        "<br/>"
                      ),
                    }}
                  />
                </p>
                {data.prize !== 0 && (
                  <h6
                    className="font-weight-bold mt-auto pt-2 pb-3"
                    style={{ fontSize: "1rem" }}
                  >
                    Price :{" "}
                    {data.discount ? (
                      <span className="text-crass">{data.discount}</span>
                    ) : (
                      ""
                    )}{" "}
                    ₹{data.prize} /
                  </h6>
                )}
                <p
                  className="font-bold text-white"
                  style={{ fontSize: "18px" }}
                >
                  Get started
                </p>
              </section>
              <div></div>
            </li>
          ))}
        </ul>
        <h6
          className="m-auto text-center"
          style={{ width: "80%", lineHeight: "36px" }}
        >
          <span style={{ fontSize: "2rem" }}>"</span> Yuukke is your all-in-one
          platform to connect, learn, and grow. Build meaningful relationships,
          access seamless in-built video conferencing, upskill through a diverse
          Learning Hub, and find mentors tailored to your goals — all designed
          to empower your personal and professional journey.{" "}
          <span style={{ fontSize: "2rem" }}>"</span>
        </h6>
      </section>
      <section
        ref={optionsMenu}
        className="service-wrap text-center mt-5 p-3 mb-5"
        style={{
          background: "#04093F1C",
          borderRadius: "5px",
          backgroundImage:
            "linear-gradient(-20deg, #ddd6f3 0%, #faaca8 100%, #faaca8 100%)",
        }}
      >
        <BlockUi
          tag="div"
          message="Loading, please wait"
          style={{ minHeight: "134px" }}
          className="wallLoadingPage pb-3"
          blocking={state.loader}
        >
          <h3 className="service-head-under mb-4">LET'S GET STARTED</h3>
          <ul className="service-wis mb-5">
            {menuOptions.map((item, i) => (
              <li key={i} className="d-flex flex-column align-items-center">
                <div
                  className=" p-3"
                  style={{
                    background: state.activeMenu >= i ? "#a00030" : "white",
                  }}
                >
                  <img
                    src={item.icon}
                    className="h-auto"
                    alt=""
                    style={{ width: "4rem" }}
                  />
                </div>
                <p className="mt-2 font-bold">{item.title}</p>
              </li>
            ))}
          </ul>
          {
            // state.activeMenu === 1 ? (
            //   <>
            //     <form
            //       onSubmit={(e) => sendOtp(e, 2)}
            //       className="card p-3 m-auto"
            //       style={{ maxWidth: "30rem", textAlign: "start" }}
            //     >
            //       {" "}
            //       <label className="mt-3 form-label">Email *</label>
            //       <input
            //         className="text-input-v2"
            //         type="email"
            //         value={state.email}
            //         onChange={(e) => {
            //           updateState("email", e.target.value);
            //         }}
            //         fullWidth={true}
            //         required
            //       />
            //       <label className="mt-3 form-label">Phone Number *</label>
            //       <PhoneInput
            //         country={"in"}
            //         containerClass="input-dark"
            //         phoneNumber={state.phoneNumber}
            //         onChange={(phone) => updateState("phoneNumber", phone)}
            //         required={true}
            //       />
            //       <div className="pl-2 pr-2">
            //         <button
            //           className="w-100 mt-4"
            //           type="submit"
            //           value="Submit"
            //           // onClick={(e) => updateDetails(e)}
            //         >
            //           Send Otp To Given Mobile Number
            //         </button>
            //       </div>
            //     </form>
            //   </>
            // ) :
            state.activeMenu === 1 ? (
              <div
                className="card p-3 m-auto"
                style={{ maxWidth: "30rem", textAlign: "start" }}
              >
                {" "}
                <h4
                  style={{ fontSize: "14px" }}
                  className="mb-3 justify-content-center align-items-center bg-white flex-column"
                >
                  OTP Sent To Your Mobile Number, <b>{state.phoneNumber}</b>
                </h4>
                <Card className="profileCard align-items-center">
                  <p
                    style={{ fontSize: "12px" }}
                    className="text-secondary mt-3 text-center"
                  >
                    Please enter your otp !
                  </p>
                  <div className="p-3 d-flex g-1 align-items-center justify-content-center service-otp">
                    <OtpInput
                      length={6}
                      onOtpSubmit={(otp) => updateState("otp", otp)}
                    />
                  </div>
                  <button
                    className="mt-3 mb-3"
                    onClick={() => verifyOtp()}
                    style={{ width: "fit-content" }}
                  >
                    Verify
                  </button>
                </Card>
              </div>
            ) : state.activeMenu === 3 ? (
              <>
                <Card
                  className="profileCard align-items-center p-3 m-auto"
                  style={{ minHeight: "21rem", maxWidth: "21rem" }}
                >
                  <img
                    onClick={() => loginYuukke()}
                    src="https://corporate.yuukke.com/bcav2/auth/image/7cdb6739-68d5-407b-ac71-4eccc422685c"
                    alt=""
                    className="w-100 h-100 c-pointer"
                  />
                  {/* Service created successfully ,now you can visit your service for
              <b>Yuukke</b> app using this phone number{" "}
              <b>{state.phoneNumber}</b> or given email.
              <button className="mt-3" onClick={() => loginYuukke()}>
                <IoLogInOutline className="mr-2 svg-icons  text-white" />
                Login Yuukke
              </button> */}
                </Card>{" "}
              </>
            ) : state.activeMenu === 2 ? (
              <div>
                <div
                  className="p-4"
                  style={{
                    background: "rgb(252, 185, 0)",
                    borderRadius: "5px",
                  }}
                >
                  <h4 className="mt-3 form-label">
                    Choose Your Suitable Plan{" "}
                  </h4>
                  <span className="badge badge-info">Save up to 40 %</span>
                </div>
                {state.subscriptionId && (
                  <RazorPay
                    email={state.email}
                    name={state.userName}
                    userId={null}
                    contactNumber={state.phoneNumber}
                    subscriptionId={state.subscriptionId}
                    amount={state.paymentAmount}
                    type={"Service-Register"}
                    updatePaymentStatus={updatePaymentStatus}
                  />
                )}
                {/* <div className="d-flex p-4 flex-wrap g-1 p-4 justify-content-center align-items-center">
              {subscriptionList.map((item, index) => (
                <SubscriptionCard
                  data={item}
                  index={index}
                  selectedTeam={openPaymentPage}
                />
              ))}
            </div> */}

                <h6 className="pt-5 pb-3">Pricing & Features</h6>
                <ul
                  className="d-flex flex-wrap align-items-center pb-5 justify-content-center"
                  style={{ gap: "2rem" }}
                >
                  {subscriptionList.map((data, i) => (
                    <li
                      className="service-card l-none"
                      key={i}
                      style={{ width: "16rem" }}
                      onClick={() => openPaymentPage(data.prize, data.id)}
                      // onClick={(e) => changeScreen(e, state.activeMenu)}
                    >
                      <section className="p-3">
                        <h5 className="m-0 font-weight-bold mb-3">
                          {data.subscriptionName}
                        </h5>
                        <p
                          className="f-12 mt-2 mb-2"
                          style={{ minHeight: "3rem" }}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.subscribeDescription.replace(
                                /<p><\/p>/g,
                                "<br/>"
                              ),
                            }}
                          />
                        </p>
                        {data.prize !== 0 && (
                          <h6
                            className="font-weight-bold mt-auto pt-2 pb-3"
                            style={{ fontSize: "1rem" }}
                          >
                            Price : {data.prize}
                          </h6>
                        )}
                        <p
                          className="font-bold text-white"
                          style={{ fontSize: "18px" }}
                        >
                          Get started
                        </p>
                      </section>
                      <div></div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : state.activeMenu === 0 ? (
              <form
                onSubmit={(e) => changeScreen(e, 0)}
                className="card  m-auto service-form"
              >
                <label className="mt-2 form-label">Your Name *</label>
                <input
                  className="text-input-v2"
                  variant="outlined"
                  type="text"
                  value={state.userName}
                  onChange={(e) => {
                    updateState("userName", e.target.value);
                  }}
                  required
                />
                <label className="mt-3 form-label">Your Service</label>
                <input
                  className="text-input-v2"
                  type="text"
                  value={state.businessName}
                  onChange={(e) => {
                    updateState("businessName", e.target.value);
                  }}
                />
                <label className="mt-3 form-label">Your Email *</label>
                <input
                  className="text-input-v2"
                  type="email"
                  value={state.email}
                  onChange={(e) => {
                    updateState("email", e.target.value);
                  }}
                  fullWidth={true}
                  required
                />
                <label className="mt-3 form-label">Your Phone Number *</label>
                <PhoneInput
                  country={"in"}
                  containerClass="input-dark"
                  phoneNumber={state.phoneNumber}
                  onChange={(phone) => updateState("phoneNumber", phone)}
                  required={true}
                />

                <label className="mt-3 form-label">
                  Languages You are Comfortable Communicating *
                </label>
                <Select
                  value={state.languageList}
                  options={languagelist}
                  onChange={(e) => updateState("languageList", e)}
                  required
                  isMulti
                  className="z-1001"
                />
                <label className="mt-3 form-label">
                  Category of Your Service *
                </label>
                <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center">
                  {state.filterCategoryList.map((data, i) => (
                    <Chip
                      key={i}
                      style={{
                        // overflow: "hidden",
                        // Whitespace: "nowrap",
                        fontSize: "12px",
                        // textOverflow: "ellipsis",
                        textTransform: "capitalize",
                        backgroundColor: data.select ? "#a00030" : "#e0e0e0",
                        color: data.select ? "white" : "black",
                        fontWeight: data.select ? "bold" : "500",
                      }}
                      label={data.label}
                      onClick={() => makeCategoryChanges(data)}
                    />
                  ))}
                </div>
                <label className="mt-3 form-label">
                  Few Words About Your Service*
                </label>
                <textarea
                  className="text-area"
                  style={{
                    border: "1px solid gainsboro",
                  }}
                  required
                  value={state.about}
                  type="text"
                  onChange={(e) => {
                    updateState("about", e.target.value);
                  }}
                />
                <label className="mt-3 form-label">Service logo</label>

                {!state.serviceImage && (
                  <StepperFileOption
                    onUploadSuccess={serviceImageUpdate}
                    acceptedType=".jpg,.jpeg,.png,.svg"
                    count={1}
                    multiple={false}
                  />
                )}
                {state.serviceImage &&
                  state.serviceImage.length !== 0 &&
                  state.serviceImage.map((image, index) => (
                    <ShowImageFile
                      files={image}
                      removeFiles={removeFiles}
                      keyFile="serviceImage"
                      index={index}
                      count={1}
                    />
                  ))}
                <button type="submit" value="Submit" className="mt-4">
                  Get Your Otp
                </button>
              </form>
            ) : (
              <div
                className="card p-3 m-auto"
                style={{ maxWidth: "30rem", textAlign: "start" }}
              >
                <ul className="mt-4   d-flex flex-column gap-1">
                  <li>
                    <b>Your Details:</b> Begin by providing your personal
                    information, including your name, service name, and other
                    necessary details.
                  </li>
                  <li>
                    <b>Authentication Details:</b> For creating your yuukke
                    account, you'll need to authenticate your identity by
                    providing authentication details.
                  </li>
                  <li>
                    <b>Mobile Verification:</b> Next, verify your mobile number
                    to ensure seamless communication and security.
                  </li>
                  <li>
                    <b>Subscription Plan:</b> Choose the subscription plan that
                    best suits your needs and proceed with payment.
                  </li>
                  <li>
                    <b>Successfully Profile Created:</b> Congratulations! Your
                    profile is now successfully created, and you can start using
                    our services right away.
                  </li>
                </ul>
                <button
                  className="mb-4 mt-4"
                  onClick={(e) => changeScreen(e, 0)}
                >
                  Start My Page
                </button>
              </div>
            )
          }
        </BlockUi>
      </section>
      {state.videoFile && (
        <Modal show="true" onHide={() => updateState("videoFile", false)}>
          <h6 className="font-bold p-4">
            Steps for create service in yuukke app
          </h6>
          <Form id="profileForm">
            <Modal.Body>
              {
                <ReactPlayer
                  className="player"
                  controls
                  url={
                    "https://customer-3uw4nujlakj1ub6w.cloudflarestream.com/5df53024f5a24a78bd50d60af3567a70/manifest/video.m3u8"
                  }
                />
              }
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                style={{ color: "#ffffff", marginRight: "12px" }}
                onClick={() => updateState("videoFile", false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
      {/* <h6
        className="m-auto text-center service-wrap  mt-5"
        style={{ lineHeight: "36px" }}
      >
        <span style={{ fontSize: "2rem" }}>"</span>
        Access Yuukke seamlessly on both app and web, giving you the freedom to
        manage your business and transactions on your preferred device. Whether
        you're buying, selling, or seeking the perfect gift, it also provides a
        e-commerce experience. Streamline your journey with Yuukke—whether
        you’re shopping for the ideal gift or growing your business, everything
        you need is in one place.
        <span style={{ fontSize: "2rem" }}>"</span>
      </h6> */}
      <div className="service-wrap text-center">
        <h3 className="service-head-under mb-5">How To Launch Your Service</h3>
        <ul className="d-flex flex-wrap gap-1  l-none align-items-start justify-content-center">
          {started.map((item, i) => (
            <li key={i} className="service-box" style={{ textAlign: "start" }}>
              <h5>{i + 1}</h5>
              <h4>{item.heading}</h4>
              <h6 style={{ fontSize: "1.3rem" }} className="mt-3">
                {item.title}
              </h6>
              <p>{item.description}</p>
            </li>
          ))}
        </ul>
        <button
          className="mt-3 mb-3"
          onClick={() => updateState("videoFile", true)}
        >
          Know More
        </button>
      </div>
      <ul className="service-wrap d-flex flex-column   l-none">
        <li className="service-head-under mb-4">Frequently asked questions</li>
        {faq.map((item, i) => (
          <li key={i}>
            <h6 className="mt-3">{item.question}</h6>
            <p className="text-secondary font-14">{item.answer}</p>
            <hr className="mb-0" />
          </li>
        ))}
      </ul>

      <section
        style={{ background: "#000940", fontSize: "14px" }}
        className="text-white mt-5 "
      >
        <h6 className="service-wrap mt-0 pt-5">Accepting Payment Methods</h6>
        <ul className="service-wrap d-flex flex-wrap gap-1 l-none">
          {image.map((item, i) => (
            <li key={i}>
              <img
                onClick={() => handleButtonClick()}
                src={item}
                alt=""
                className="c-pointer"
                style={{
                  width: "9rem",
                  height: "4rem",
                  background: "white",
                  borderRadius: "5px",
                }}
              />
            </li>
          ))}
        </ul>
        <div className="service-wrap pt-4 pb-5 justify-content-between d-flex flex-wrap gap-1">
          <ul className="l-none">
            <li className="mb-3 font-weight-bold" style={{ fontSize: "1rem" }}>
              Quick Links
            </li>
            {quickLinks.map((item, i) => (
              <li key={i}>
                <a
                  className="text-white"
                  style={{ fontWeight: "600" }}
                  href={item.link}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <ul className="l-none">
            <li className="mb-3 font-weight-bold" style={{ fontSize: "1rem" }}>
              Support
            </li>
            {support.map((item, i) => (
              <li key={i}>
                <a
                  style={{ fontWeight: "600" }}
                  className="text-white"
                  href={item.link}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <ul className="l-none">
            <li className="mb-3 font-weight-bold" style={{ fontSize: "1rem" }}>
              Yuukke
            </li>
            {yuukke.map((item, i) => (
              <li key={i}>
                <a
                  style={{ fontWeight: "600" }}
                  className="text-white"
                  href={item.link}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <ul className="l-none">
            <li className="mb-3 font-weight-bold" style={{ fontSize: "1rem" }}>
              Resources
            </li>
            {resource.map((item, i) => (
              <li key={i}>
                <a
                  style={{ fontWeight: "600" }}
                  className="text-white"
                  href={item.link}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <ul className="l-none" style={{ fontWeight: "600" }}>
            <li>Yuukke Global Venture Pvt Ltd.</li>
            <li>
              Y-213, 2nd Ave, Y Block, Anna Nagar,
              <br /> Chennai, Tamil Nadu 600040
            </li>
            <li
              className="c-pointer"
              onClick={(e) => handleClick(e, "support@yuukke.com")}
            >
              support@yuukke.com
            </li>
            <li
              className="c-pointer"
              onClick={(e) => openPhone(e, "9104446314646")}
            >
              +91 04446314646
            </li>
          </ul>
        </div>
      </section>
      <footer
        style={{ background: "#000000", fontSize: "14px" }}
        className="text-white p-3 text-center"
      >
        <div className="service-wrap d-flex align-items-center gap-1 justify-content-between flex-wrap">
          <p>© 2022-23 Yuukke Global Ventures Private Limited.</p>
          <ul className="l-none d-flex align-items-center justify-content-between gap-1 m-0">
            {socialIcons.map((item, i) => (
              <li key={i}>
                <a href={item.link}>{item.icon}</a>
              </li>
            ))}
          </ul>
        </div>
      </footer>
    </div>
  );
}
const ShowImageFile = (props) => {
  const file = props?.files;
  const name = file?.name ? file.name : "image";
  return (
    <div key={props.index}>
      <Chip
        icon={<MdOutlineAttachFile />}
        className="fileChip"
        label={name.substring(0, 12)}
        size="small"
        onDelete={() => props.removeFiles(props?.keyFile, props?.index)}
        variant="outlined"
      />

      {file && (
        <img
          style={{
            margin: "8px",
            width: "auto",
            height: "80px",
          }}
          src={
            file?.name
              ? URL.createObjectURL(file)
              : `${API_URL}auth/image/${file}`
          }
          alt="img"
        ></img>
      )}
    </div>
  );
};

export default withRouter(ServiceLandingPage);
